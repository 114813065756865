import { Component, Host, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { LoginService } from '../../../services/login/login.service';
import { LoginComponent } from '../login.component';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-standard-user',
  templateUrl: './standard-user.component.html',
  styleUrls: ['./standard-user.component.scss']
})
export class StandardUserComponent implements OnInit {

  form = new FormGroup({});

  model = { email: '', password: '' };
  fields: FormlyFieldConfig[] = [
    {
      key: 'email',
      type: 'input',
      templateOptions: {
        label: 'E-Mail',
        required: true,
      }
    },
    {
      key: 'password',
      type: 'input',
      templateOptions: {
        label: 'Passwort',
        type: 'password',
        required: true,
      }
    }
  ];

  constructor(@Host() private _parent: LoginComponent,
    private _login: LoginService,
    private _router: Router,
    private _snackBar: MatSnackBar) { }

  ngOnInit() {

  }

  submit() {
    return new Promise((resolve, reject) => {
      this.form.markAllAsTouched();
      if (!this.form.valid) {
        return reject();
      }

      this._parent.showLoader = true;

      this._login.validateCredentials(this.model.email, this.model.password, '', 1).then(data => {
        this._parent.showLoader = false;
        this._login.setLoggedIn(true);
        this._router.navigate(['/home']);
        resolve(true);
      }).catch(error => {
        this._parent.showLoader = false;
        this._snackBar.open(error, 'ok', {
          duration: 3000
        });
        reject();
      });
    });
  }

}
