import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-standard-profile',
  templateUrl: './standard-profile.component.html',
  styleUrls: ['./standard-profile.component.scss']
})
export class StandardProfileComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
