<div class="row mt-3">
  <div class="col-12 mb-2">
    <div class="clearfix">
      <mat-checkbox (change)="toggleAll($event)" [checked]="isCheckedAll" class="float-start">Alle auswählen</mat-checkbox>
      <button mat-icon-button class="float-end" (click)="getGroups()" matTooltip="Aktualisieren"><mat-icon>refresh</mat-icon></button>
    </div>
    <hr />
  </div>
  <div class="col-lg-6 col-md-6 col-12" *ngFor="let group of groupList">
    <mat-checkbox (change)="select(group, $event)" [checked]="group.checked">{{ group.name }}</mat-checkbox>
  </div>
</div>