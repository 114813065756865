import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ConfirmAlertComponent } from 'src/app/utils/confirm-alert/confirm-alert.component';
import { environment } from 'src/environments/environment';
import { UserGroupDialogComponent } from './user-group-dialog/user-group-dialog.component';

@Component({
  selector: 'app-organization-groups',
  templateUrl: './organization-groups.component.html',
  styleUrls: ['./organization-groups.component.scss']
})
export class OrganizationGroupsComponent implements OnInit {

  displayedColumns = ['name', 'count', 'actions'];
  dataSource = new MatTableDataSource<any[]>();
  showLoader = false;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private _http: HttpClient,
    private _snackBar: MatSnackBar,
    private _dialog: MatDialog) { }

  ngOnInit(): void {
    this.load();
  }

  load() {
    this.showLoader = true;
    this._http.get<any[]>(`${environment.api_url}/cloud/groups`).subscribe(data => {
      this.dataSource.data = data;
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.showLoader = false;
    });
  }

  editGroup(elem) {
    const dialogRef = this._dialog.open(OrganizationGroupsPopupComponent, {
      width: '350px',
      data: JSON.parse(JSON.stringify(elem))
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.load();
      }
    });
  }

  deleteGroup(elem) {
    let message = 'Soll diese Gruppe wirklich gelöscht werden?';

    if (elem.count > 0) {
      message = '<b>Achtung!</b> Diese Gruppe enthält Benutzer,<br />soll fortgefahren werden?'
    }

    let dialogRef = this._dialog.open(ConfirmAlertComponent, {
      data: {
        title: 'Wirklich löschen?',
        message: message
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this._http.delete(`${environment.api_url}/cloud/groups/${elem.id}`).subscribe(data => {
          this.load();
          this._snackBar.open('Gruppe wurde erfolgreich gelöscht!', 'ok', {
            duration: 3000,
            horizontalPosition: 'end'
          });
        }, error => {
          this._snackBar.open('Fehler beim Löschen der Gruppe!', 'ok', {
            duration: 3000,
            horizontalPosition: 'end'
          });
        });
      }
    });
  }

  createGroup() {
    this._dialog.open(OrganizationGroupsPopupComponent, {
      width: '350px',
      data: {
        name: ''
      }
    }).afterClosed().subscribe(result => {
      this.load();
    });
  }

  editUser(elem) {
    this._dialog.open(UserGroupDialogComponent, {
      width: '800px',
      data: {
        id: elem.id
      }
    }).afterClosed().subscribe(result => {
      this.load();
    });
  }
}

@Component({
  selector: 'app-organization-groups-popup',
  templateUrl: './edit-popup.html'
})
export class OrganizationGroupsPopupComponent implements OnInit {
  constructor(public _dialogRef: MatDialogRef<ConfirmAlertComponent>,
    @Inject(MAT_DIALOG_DATA) public _data,
    private _http: HttpClient,
    private _snackBar: MatSnackBar) { }

  ngOnInit(): void {}

  save() {
    if (this._data?.id) {
      this._http.put(`${environment.api_url}/cloud/groups/${this._data.id}`, {
        name: this._data.name
      }).subscribe(data => {
        this._snackBar.open('Gruppe wurde aktualisiert.', 'ok', {
          duration: 3000,
          horizontalPosition: 'end'
        });
        this._dialogRef.close(true);
      }, error => {
        this._snackBar.open('Fehler beim Aktualisieren der Gruppe!', 'ok', {
          duration: 3000,
          horizontalPosition: 'end'
        });
      });
    } else {
      this._http.post(`${environment.api_url}/cloud/groups`, {
        name: this._data.name
      }).subscribe(data => {
        this._snackBar.open('Gruppe wurde erstellt.', 'ok', {
          duration: 3000,
          horizontalPosition: 'end'
        });
        this._dialogRef.close(true);
      }, error => {
        this._snackBar.open('Fehler beim Aktualisieren der Gruppe!', 'ok', {
          duration: 3000,
          horizontalPosition: 'end'
        });
      });
    }
  }
}
