<div class="row">
  <div class="col-12">
    <mat-card class="m-3">
      <mat-card-title>
        <div class="clearfix">
          <span class="float-start">
            <mat-icon>directions_car</mat-icon>
            Fahrzeugverwaltung
          </span>
          <button
            class="float-end"
            color="primary"
            (click)="createVehicle()"
            mat-raised-button>
              Neues Fahrzeug
              <mat-icon>add_to_photos</mat-icon>
            </button>
        </div>
      </mat-card-title>

      <mat-card-subtitle>
        In dieser Ansicht können Ihre Fahrzeuge verwaltet werden.
      </mat-card-subtitle>

      <mat-card-content>
        <div *ngIf="accountType == 1; else elseBlock;" class="alert alert-danger pt-2">
          <b>Fehler!</b> Für diesen Bereich haben Sie keinen Zugang!
        </div>
        <ng-template #elseBlock>
          <div *ngIf="data.length == 0" class="alert alert-info pt-2">
            <mat-icon>info</mat-icon> <span class="aligned-with-icon">Sie haben keine Fahrzeuge registriert.</span>
          </div>
          <mat-form-field class="full-width">
            <input
              matInput
              placeholder="Fahrzeug suchen"
              (keyup)="search($event.target.value)" />
          </mat-form-field>
          <table mat-table [dataSource]="dataSource" matSort class="pt-2 full-width">
            <!-- Name Column -->
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
              <td mat-cell *matCellDef="let element"> {{ element.name }} </td>
            </ng-container>

            <!-- A ction Column -->
            <ng-container matColumnDef="actions" justify="end">
              <th mat-header-cell *matHeaderCellDef>
                <button mat-icon-button (click)="load()">
                  <mat-icon>refresh</mat-icon>
                </button>
              </th>
              <td mat-cell *matCellDef="let element">
                <button
                  mat-icon-button
                  (click)="editVehicle(element)"
                  matTooltip="Fahrzeug bearbeiten">
                  <mat-icon>edit</mat-icon>
                </button>
                <button
                  mat-icon-button
                  (click)="delete(element)"
                  matTooltip="Fahrzeug löschen">
                  <mat-icon>delete</mat-icon>
                </button>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
          <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
        </ng-template>
      </mat-card-content>
    </mat-card>
  </div>
</div>

<mat-progress-bar
    *ngIf="showLoader"
    mode="query"
    class="login-spinner"></mat-progress-bar>
