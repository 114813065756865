import { environment } from './../../../environments/environment';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { LoginService } from '../../services/login/login.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';

import * as dayjs from 'dayjs';
import { Title } from '@angular/platform-browser';
import { RestartAlertComponent } from 'src/app/utils/restart-alert/restart-alert.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-endpoints',
  templateUrl: './endpoints.component.html',
  styleUrls: ['./endpoints.component.scss']
})
export class EndpointsComponent implements OnInit {

   @ViewChild('logmonitor') private logmonitor: ElementRef;

  accountType = 0;
  endpointOnline = false;
  lastSeen = 'nicht verfügbar';
  name = '';
  isNearBottom = true;
  preventSearching = false;
  filter: any = {
    query: '',
    timeFrame: '3600'
  }

  logs: any[] = [];

  refreshTimerId;

  constructor(private _login: LoginService,
    private _snackBar: MatSnackBar,
    private _http: HttpClient,
    private _dialog: MatDialog,
    private _title: Title) { }

  ngOnInit(): void {
    this._login.userSource.subscribe(user => {
      if(user) {
        this.accountType = user.type;

        if(user.type == 2){
          this.loadControllerData();
          this.loadLogs();
          this._title.setTitle('Firedesk - Endpunkte');

          this.refreshTimerId = setInterval(() => {
            this.loadControllerData();
            this.loadLogs();
          }, 5000);
        }else {
          this._title.setTitle('Keine Berechtigung!');
        }
      }
    });
  }

  ngOnDestroy(): void {
    clearInterval(this.refreshTimerId);
  }

  filterItems() {
    const dialogRef = this._dialog.open(EndpointsFilterPopupComponent, {
      width: '400px',
      data: this.filter,
      disableClose: true
    });
    this.preventSearching = true;

    dialogRef.afterClosed().subscribe(result => {
      this.preventSearching = false;
      this.loadLogs();
    });
  }

  loadControllerData() {
    this._http.get<any>(`${environment.api_url}/cloud/gateways`).subscribe(data => {
      if(data) {
        this.endpointOnline = data.isOnline;
        this.name = data.name;
      }
    }, error => {
      this._snackBar.open('Konnte Endpunktgeräte Daten nicht abrufen!', 'schließen', {
        duration: 4000
      });
      console.error(error);
    });
  }

  loadLogs() {
    if (this.preventSearching) 
      return;
    const context = this;
    let httpParams = new HttpParams();
    Object.keys(this.filter).forEach(function (key) {
        httpParams = httpParams.append(key, context.filter[key]);
    });

    this._http.get<any[]>(`${environment.api_url}/cloud/gateways/logs`, {
      params: httpParams
    }).subscribe(data => {
      if(data && data.length != 0) {
        this.logs = data;
        setTimeout(() => {
          if (this.isNearBottom) {
            this.logmonitor.nativeElement.scrollTop = this.logmonitor.nativeElement.scrollHeight;
          }
        }, 400);
      } else {
        this.logs = [{
          timestamp: null,
          message: 'Aktuell sind keine Logs vorhanden. Passen Sie den Filter an um weitere Ergebnisse anzuzeigen.',
          type: 'WARN'
        }];
      }
    }, error => {
      this._snackBar.open('Konnte Endpunktgeräte Daten nicht abrufen!', 'schließen', {
        duration: 4000
      });
      console.error(error);
    });
  }

  scrolled(event: any): void {
    this.isNearBottom = this.isUserNearBottom(this.logmonitor.nativeElement);
  }

  isUserNearBottom(scrollContainer: any): boolean {
    const threshold = 150;
    const position = scrollContainer.scrollTop + scrollContainer.offsetHeight;
    const height = scrollContainer.scrollHeight;
    return position > height - threshold;
  }

  restartDevice() {
    const dialogRef = this._dialog.open(RestartAlertComponent, {
      width: '300px'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this._http.get(`${environment.api_url}/cloud/gateways/restart`).subscribe(result => {
          this._snackBar.open('Neustart wird angefordert ...', 'ok', {
            duration: 3000
          });
        }, error => {
          this._snackBar.open('Fehler beim Neustart des Endgerätes!', 'ok', {
            duration: 3000
          });
        });
      }
    });
  }
}


@Component({
  selector: 'app-endpoints-popup',
  templateUrl: './filter-popup.html'
})
export class EndpointsFilterPopupComponent implements OnInit {

  constructor(public _dialogRef: MatDialogRef<EndpointsFilterPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public _data) {}

  ngOnInit(): void {
    
  }

}