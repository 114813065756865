<div class="row">
  <div class="col-lg-8 col-12">
    <mat-card class="m-3">
      <mat-card-title>
        <div class="clearfix">
          <span class="float-start">
            <mat-icon>notification_important</mat-icon>
            <span *ngIf="data">Einsatz vom {{dayjs(data.date).format('DD.MM.YYYY HH:mm') }}</span>
          </span>
        </div>
      </mat-card-title>

      <mat-card-subtitle>
        In dieser Ansicht können Sie Ihre Einsätzedetails sehen und weitere Informationen verwalten
      </mat-card-subtitle>

      <mat-card-content>
        <div class="alert alert-danger" *ngIf="inValid">
          <b>Fehler:</b> Alarmierung konnte nicht gefunden werden!
        </div>

        <div *ngIf="!inValid && data" class="mt-2">
          <form [formGroup]="form">
            <formly-form [form]="form" [fields]="fields" [model]="data"></formly-form>
          </form>

          <div class="clearfix">
            <div class="float-start">
              <button mat-raised-button color="gray" [routerLink]="['/alarms']">
                <mat-icon>keyboard_backspace</mat-icon>
                Zurück zur Alarmliste
              </button>
            </div>
            <div class="float-end">
              <button mat-raised-button color="primary" (click)="save()">
                Speichern <mat-icon>save</mat-icon>
              </button>
            </div>
          </div>
        </div>

        <ngx-spinner [fullScreen]="false" type="ball-scale-multiple" size="medium"></ngx-spinner>
      </mat-card-content>
    </mat-card>
  </div>

  <div class="col-lg-4 col-12">
    <mat-card class="m-3">
      <mat-card-title>
        <div class="clearfix">
          <span class="float-start">
            <mat-icon>done_all</mat-icon>
            <span>Rückmeldungen</span>
          </span>
        </div>
      </mat-card-title>

      <mat-card-content>
        <div class="alert alert-danger" *ngIf="inValid">
          <b>Fehler:</b> Rückmeldungen konnten nicht geladen werden!
        </div>

        <div class="alert alert-info" *ngIf="!inValid && responses.length == 0">
          Noch keine Rückmeldungen empfangen!
        </div>

        <mat-list *ngIf="!inValid">
          <mat-list-item *ngFor="let response of responses">
            <mat-icon mat-list-icon [color]="response.response == 1 ? 'success' : response.response == 0 ? 'warn' : 'secondary'">
              {{ response.response == 1 ? 'thumb_up' : '' }}
              {{ response.response == 0 ? 'thumb_down' : '' }}
              {{ response.response == -1 ? 'question_mark' : '' }}
            </mat-icon>
            <div mat-line class="clearfix">
              <div class="float-start">
                {{response.firstname}} {{response.lastname}}
              </div>
            </div>
            <div mat-line *ngIf="response.date != null">
              {{  dayjs(response.date).format('HH:mm:ss DD.MM.YYYY') }}
            </div>
          </mat-list-item>
        </mat-list>

        <ngx-spinner [fullScreen]="false" type="ball-scale-multiple" size="medium"></ngx-spinner>
      </mat-card-content>
    </mat-card>

    <mat-card class="m-3">
      <mat-card-title>
        <div class="clearfix">
          <span class="float-start">
            <mat-icon>groups</mat-icon>
            Alarmierungsgruppen
          </span>
        </div>
      </mat-card-title>
      <mat-card-content>
        <mat-list>
          <mat-list-item *ngFor="let channel of data?.channels">
            <mat-icon mat-list-icon>feedback</mat-icon>
            <div mat-line>{{channel.name}}</div>
            <div mat-line> {{ dayjs(channel.timestamp).format('HH:mm:ss DD.MM.YYYY') }} </div>
          </mat-list-item>
        </mat-list>

        <ngx-spinner [fullScreen]="false" type="ball-scale-multiple" size="medium"></ngx-spinner>
      </mat-card-content>
    </mat-card>
  </div>
</div>