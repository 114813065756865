import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-standard-alarms',
  templateUrl: './standard-alarms.component.html',
  styleUrls: ['./standard-alarms.component.scss']
})
export class StandardAlarmsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
