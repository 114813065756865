<a mat-list-item routerLink="/home" routerLinkActive="active" (click)="_parent.drawer.toggle()">
  <mat-icon>home</mat-icon>
  <span class="nav-caption ms-1">Startseite</span>
</a>
<a mat-list-item routerLink="/alarms" routerLinkActive="active" (click)="_parent.drawer.toggle()">
  <mat-icon>notification_important</mat-icon>
  <span class="nav-caption ms-1">Einsätze</span>
</a>
<a mat-list-item routerLink="/groups" routerLinkActive="active" (click)="_parent.drawer.toggle()">
  <mat-icon>group</mat-icon>
  <span class="nav-caption ms-1">Meine Gruppen</span>
</a>
<a mat-list-item routerLink="/devices" routerLinkActive="active" (click)="_parent.drawer.toggle()">
  <mat-icon>devices</mat-icon>
  <span class="nav-caption ms-1">Meine Geräte</span>
</a>
<a mat-list-item href="https://ifttt.com/firedesk" target="_blank">
  <img src="../../../../assets/ifttt.png" style="margin-left: 2px" width="21" >
  <span class="ms-1 nav-caption">IFTTT</span>
</a>
