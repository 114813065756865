<h1 mat-dialog-title [innerHTML]="title"></h1>

<div mat-dialog-content>
  <p style="font-size: 18px" [innerHTML]="message"></p>
</div>

<div mat-dialog-actions>
  <span class="big-spacer"></span>
  <button mat-button (click)="onDismiss()">Nein</button>
  <button mat-button (click)="onConfirm()">Ja</button>
</div>
