import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-alarms-groups',
  templateUrl: './alarms-groups.component.html',
  styleUrls: ['./alarms-groups.component.scss']
})
export class AlarmsGroupsComponent implements OnInit {

  groupList = [];
  isCheckedAll = false;
  someSelected = false;

  constructor(private _http: HttpClient,
    private _snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.getGroups();
  }

  getGroups() {
    this.isCheckedAll = false;
    this._http.get(`${environment.api_url}/cloud/groups`).subscribe((data: any) => {
      this.groupList = data;
    }, error => {
      console.error(error);
      this._snackBar.open('Fehler beim Abrufen der Gruppen', 'ok', {
        duration: 3000
      });
    });
  }

  toggleAll($event) {
    this.groupList.forEach(elem => {
      elem.checked = $event.checked;
    });
    this.isCheckedAll = $event.checked;
    this.isSomeSelected();
  }

  select(elem, $event) {
    elem.checked = $event.checked;
    this.isCheckedAll = this.groupList.filter(g => g.checked).length == this.groupList.length;
    this.isSomeSelected();
  }

  isSomeSelected() {
    let status = false;
    this.groupList.forEach(elem => {
      if (elem.checked) {
        status = true;
      }
    });
    this.someSelected = status;
  }
}
