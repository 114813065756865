import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { LoginService } from 'src/app/services/login/login.service';
import { environment } from 'src/environments/environment';

import * as dayjs from 'dayjs';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmAlertComponent } from '../../utils/confirm-alert/confirm-alert.component';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-devices',
  templateUrl: './devices.component.html',
  styleUrls: ['./devices.component.scss']
})
export class DevicesComponent implements OnInit {

  dayjs = dayjs;
  accountType = 0;
  displayedColumns = ['lastlogin', 'platform', 'actions'];
  dataSource = new MatTableDataSource<any[]>();
  showLoader = false;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private _login: LoginService,
    private _http: HttpClient,
    private _dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private _title: Title) { }

  ngOnInit(): void {
    this._login.userSource.subscribe(user => {
      if(user) {
        this.accountType = user.type;

        if(this.accountType == 1) {
          this.load();
        }
      }
    });
    this._title.setTitle('Firedesk - Geräte');
  }

  load() {
    this.showLoader = true;
    this._http.get<any[]>(`${environment.api_url}/cloud/devices`).subscribe(data => {
      this.dataSource.data = data;
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.showLoader = false;
    });
  }

  delete(id) {
    const ref = this._dialog.open(ConfirmAlertComponent, {
      data: {
        title: 'Soll das Gerät gelöscht werden?',
        message: 'Soll dieses Gerät wirklich gelöscht werden?<br />' +
          'Dabei werden <b>alle</b> Daten gelöscht und das Gerät automatisch ausgeloggt.'
      }
    });

    ref.afterClosed().subscribe(status => {
      if(status) {
        this._http.delete(`${environment.api_url}/cloud/devices/${id}`).subscribe(data => {
          this._snackBar.open('Gerät wurde erfolgreich gelöscht!', 'ok', {
            duration: 3000
          });
          this.load();
        });
      }
    });
  }

  sendPush(id) {
    this._http.post(`${environment.api_url}/cloud/devices`, {
      id: id
    }).subscribe(data => {
      this._snackBar.open('Benachrichtigung wurde versendet.', 'ok', {
        duration: 3000
      });
      this.showLoader = false;
    });
  }

  sendAlarm(id) {
    this._http.put(`${environment.api_url}/cloud/devices`, {
      id: id
    }).subscribe(data => {
      this._snackBar.open('Test Alarm wurde versendet.', 'ok', {
        duration: 3000
      });
      this.showLoader = false;
    });
  }
}
