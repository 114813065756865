<div class="row">
  <div class="col-12">
    <mat-card class="m-3">
      <mat-card-content>
        <div *ngIf="accountType == 1; else elseBlock;" class="alert alert-danger pt-2">
          <b>Fehler!</b> Für diesen Bereich haben Sie keinen Zugang!
        </div>

        <ng-template #elseBlock>
          <div class="row m-2">
            <div class="col-12">
              <div class="clearfix">
                <span class="status float-start" [ngClass]="endpointOnline ? 'online' : 'offline'"></span>
                <h1 class="float-start ms-3">{{name}}</h1>
                <h1 class="float-end"
                  [matTooltip]="endpointOnline ? 'Gerät ist Online und Betriebsbereit' : 'Gerät ist Offline und hat keine Verbindung zur Firedesk Cloud'">
                  <span class="badge"
                    [ngClass]="endpointOnline ? 'badge-success' : 'badge-danger'">
                    {{ endpointOnline ? 'ONLINE' : 'OFFLINE' }}
                  </span>
                </h1>
              </div>
              <hr />

              <div class="mb-2" class="row clearfix">
                <div class="col-6">
                  <button mat-raised-button
                    (click)="restartDevice()"
                    [disabled]="!endpointOnline"
                    class="mb-1 me-2"
                    color="success">
                    <mat-icon>cached</mat-icon>
                    Neustarten
                  </button>
                  <button mat-raised-button
                    color="orange">
                    <mat-icon>cloud_download</mat-icon>
                    Update verfügbar!
                  </button>
                </div>

                <div class="col-6">
                  <button mat-raised-button
                    class="ms-2 mb-1 float-end"
                    routerLink="/endpoints/actions"
                    color="primary">
                    <mat-icon>layers</mat-icon>
                    Aktionen konfigurieren
                  </button>
                  <button mat-raised-button
                    class="ms-2 mb-1 float-end"
                    routerLink="/endpoints/modules"
                    color="primary">
                    <mat-icon>dns</mat-icon>
                    Module
                  </button>
                  <button mat-raised-button
                    color="primary"
                    (click)="filterItems()"
                    matTooltip="Suchergebnisse filtern"
                    class="float-end">
                    <mat-icon>search</mat-icon>
                  </button>
                </div>
              </div>

              <div #logmonitor class="logmonitor mt-2" (scroll)="scrolled($event)">
                <span *ngFor="let log of logs" [ngClass]="log.type">
                  [{{ log.type }}] {{ log.message }} <br />
                </span>
              </div>
            </div>
          </div>
        </ng-template>
      </mat-card-content>
    </mat-card>
  </div>
</div>
