<div class="row">
  <div class="col-12">
    <mat-card class="m-3">
      <mat-card-title>
        <div class="clearfix">
          <span class="float-start">
            <mat-icon>payment</mat-icon>
            Rechnungen
          </span>
        </div>
      </mat-card-title>

      <mat-card-subtitle>
        In dieser Ansicht können Ihre Rechnungen verwaltet werden.
      </mat-card-subtitle>

      <mat-card-content>
        <div *ngIf="accountType == 1; else elseBlock;" class="alert alert-danger pt-2">
          <b>Fehler!</b> Für diesen Bereich haben Sie keinen Zugang!
        </div>
        <ng-template #elseBlock>
          <div *ngIf="data.length == 0" class="alert alert-info pt-2">
            <mat-icon>info</mat-icon> <span class="aligned-with-icon">Sie haben aktuell keine Rechnungen.</span>
          </div>

          <div class="table-responsive">
            <table mat-table [dataSource]="dataSource" matSort class="pt-2 full-width">
              <!-- Date Column -->
              <ng-container matColumnDef="date">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Datum </th>
                <td mat-cell *matCellDef="let element" (click)="loadInvoice(element)"> {{ dayjs(element.date).format("DD.MM.yyyy") }} </td>
              </ng-container>
  
              <!-- Status Column -->
              <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
                <td mat-cell *matCellDef="let element" (click)="loadInvoice(element)">
                  <h5 class="badge mt-3" style="padding-left: 15px; padding-right: 15px;" [ngClass]="batchColor(element.status)">
                    <span *ngIf="element.status == 'paid'">Bezahlt</span>
                    <span *ngIf="element.status == 'open'">Offen</span>
                    <span *ngIf="element.status == 'reminder'">Mahnung</span>
                    <span *ngIf="element.status == 'cancelled'">Storniert</span>
                  </h5>
                </td>
              </ng-container>
  
              <!-- Total Column -->
              <ng-container matColumnDef="total">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Summe </th>
                <td mat-cell *matCellDef="let element" (click)="loadInvoice(element)">{{ element.total }} &euro;</td>
              </ng-container>
  
              <!-- Action Column -->
              <ng-container matColumnDef="actions" justify="end">
                <th mat-header-cell *matHeaderCellDef>
                  <button mat-icon-button (click)="load()">
                    <mat-icon>refresh</mat-icon>
                  </button>
                </th>
                <td mat-cell *matCellDef="let element">
                  <a mat-icon-button
                    matTooltip="Rechnung herunterladen"
                    *ngIf="element.status != 'cancelled'"
                    target="_blank"
                    href="{{ env.api_url }}/cloud/invoices/{{ element.id }}/{{ _login.getToken() }}/download">
                    <mat-icon>cloud_download</mat-icon>
                  </a>
                  <button mat-icon-button
                    matTooltip="Rechnung bezahlen"
                    (click)="invoicePay(element)"
                    *ngIf="!(element.status == 'paid' || element.status == 'cancelled')">
                    <mat-icon>payment</mat-icon>
                  </button>
                </td>
              </ng-container>
  
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"
                [ngClass]="{'hover': !(row.status == 'paid' || row.status == 'cancelled')}"></tr>
            </table>
          </div>
          <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
        </ng-template>
      </mat-card-content>
    </mat-card>
  </div>
</div>
