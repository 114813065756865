<div class="row">
  <div class="col-12">
    <mat-card class="m-3">
      <mat-card-title>
        <div class="clearfix">
          <span class="float-start">
            <mat-icon>layers</mat-icon>
            <span class="aligned-with-icon">Aktionen</span>
          </span>
          <button
            class="float-end"
            color="primary"
            *ngIf="accountType == 2"
            routerLink="new"
            mat-raised-button>
              Neue Aktion
               <mat-icon>add_to_photos</mat-icon>
            </button>
        </div>
      </mat-card-title>

      <mat-card-content>
        <div *ngIf="accountType == 1; else elseBlock;" class="alert alert-danger pt-2">
          <b>Fehler!</b> Für diesen Bereich haben Sie keinen Zugang!
        </div>

        <ng-template #elseBlock>
          <div class="table-responsive">
            <table mat-table [dataSource]="dataSource" matSort class="pt-2 full-width">
              <!-- Name Column -->
              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
                <td mat-cell *matCellDef="let element"> {{element.name}} </td>
              </ng-container>

              <!-- Trigger Column -->
              <ng-container matColumnDef="trigger">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Auslöser </th>
                <td mat-cell *matCellDef="let element"> {{element.trigger}} </td>
              </ng-container>

              <!-- Trigger Column -->
              <ng-container matColumnDef="active">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Aktiv? </th>
                <td mat-cell *matCellDef="let element">
                  <mat-slide-toggle 
                    [checked]="element.active == 1"
                    (change)="toggleAction(element.id, $event)"></mat-slide-toggle>
                </td>
              </ng-container>
          
              <!-- A ction Column -->
              <ng-container matColumnDef="actions" justify="end">
                <th mat-header-cell *matHeaderCellDef>
                  <button mat-icon-button (click)="load()">
                    <mat-icon>refresh</mat-icon>
                  </button>
                </th>
                <td mat-cell *matCellDef="let element">
                  <button mat-icon-button matTooltip="{{ element.synced == 1 ? 'Synchronisiert' : 'Wird synchronisiert' }}" disabled><mat-icon>{{ element.synced == 1 ? 'cloud_done' : 'cloud_download' }}</mat-icon></button>
                  <button 
                    mat-icon-button 
                    matTooltip="Aktion bearbeiten" 
                    [routerLink]="[element.id]"
                    (click)="editAction(element)"><mat-icon>edit</mat-icon></button>
                  <button mat-icon-button matTooltip="Aktion löschen" (click)="deleteAction(element)"><mat-icon>delete</mat-icon></button>
                </td>
              </ng-container>
          
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
          </div>
          <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
        </ng-template>
      </mat-card-content>
    </mat-card>
  </div>
</div>

<mat-progress-bar
    *ngIf="showLoader"
    mode="query"
    class="login-spinner"></mat-progress-bar>
