<div class="row">
  <div class="col-12">
    <mat-card class="m-3">
      <mat-card-title>
        <div class="clearfix">
          <span class="float-start">
            <mat-icon>volume_off</mat-icon>
            Zeitschaltung / Proberufe
          </span>

          <button
            class="float-end"
            color="primary"
            (click)="createTimeout()"
            mat-raised-button>
              Neue Zeitschaltung
              <mat-icon>add_to_photos</mat-icon>
            </button>
        </div>
      </mat-card-title>

      <mat-card-subtitle>
        In dieser Ansicht können Sie Ihre Proberufe verwalten.
      </mat-card-subtitle>

      <mat-card-content>
        <div *ngIf="accountType == 1; else elseBlock;" class="alert alert-danger pt-2">
          <b>Fehler!</b> Für diesen Bereich haben Sie keinen Zugang!
        </div>
        <ng-template #elseBlock>
          <div class="table-responsive">
            <table mat-table [dataSource]="dataSource" matSort class="pt-2 full-width">
              <!-- Date Column -->
              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
                <td mat-cell *matCellDef="let element"> {{ element.name }} </td>
              </ng-container>
  
              <!-- Status Column -->
              <ng-container matColumnDef="time">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Uhrzeit </th>
                <td mat-cell *matCellDef="let element">
                  {{ element.from }} 
                  -
                  {{ element.to }} Uhr
                </td>
              </ng-container>
  
              <!-- Total Column -->
              <ng-container matColumnDef="day">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Tag </th>
                <td mat-cell *matCellDef="let element">{{ getDayDescription(element.dayOfWeek) }}</td>
              </ng-container>
  
              <!-- Action Column -->
              <ng-container matColumnDef="actions" justify="end">
                <th mat-header-cell *matHeaderCellDef>
                  <button mat-icon-button (click)="load()">
                    <mat-icon>refresh</mat-icon>
                  </button>
                </th>
                <td mat-cell *matCellDef="let element">
                  <mat-slide-toggle
                    matTooltip="Zeitschaltung aktivieren/deaktivieren"
                    [checked]="element.enabled == 1"
                    class="me-2"
                    (change)="toggleState(element, $event)">
                  </mat-slide-toggle>

                  <button mat-icon-button
                    matTooltip="Zeitschaltung bearbeiten"
                    (click)="editTimeout(element)">
                    <mat-icon>edit</mat-icon>
                  </button>

                  <button mat-icon-button
                    matTooltip="Gruppen zuweisen"
                    (click)="editGroups(element)">
                    <mat-icon>groups</mat-icon>
                  </button>

                  <button mat-icon-button
                    matTooltip="Zeitschaltung löschen"
                    (click)="deleteTimeout(element)">
                    <mat-icon>delete</mat-icon>
                  </button>
                </td>
              </ng-container>
  
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
          </div>
          <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
        </ng-template>
      </mat-card-content>
    </mat-card>
  </div>
</div>

<mat-progress-bar
    *ngIf="showLoader"
    mode="query"
    class="login-spinner"></mat-progress-bar>
    