import { ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PasswordForgotComponent } from './password-forgot/password-forgot.component';
import { RegisterOrganizationComponent } from './register-organization/register-organization.component';
import { OrganizationUserComponent } from './organization-user/organization-user.component';
import { StandardUserComponent } from './standard-user/standard-user.component';
import { LoginService } from '../../services/login/login.service';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { trigger, transition, animate, keyframes, state, style } from '@angular/animations';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  animations: [
    trigger('shake', [
      state('shakestart', style({
          transform: 'scale(1)',
      })),
      state('shakeend', style({
          transform: 'scale(1)',
      })),
      transition('shakestart => shakeend', animate('1000ms ease-in',  
        keyframes([
          style({transform: 'translate3d(-1px, 0, 0)', offset: 0.1}),
          style({transform: 'translate3d(2px, 0, 0)', offset: 0.2}),
          style({transform: 'translate3d(-4px, 0, 0)', offset: 0.3}),
          style({transform: 'translate3d(4px, 0, 0)', offset: 0.4}),
          style({transform: 'translate3d(-4px, 0, 0)', offset: 0.5}),
          style({transform: 'translate3d(4px, 0, 0)', offset: 0.6}),
          style({transform: 'translate3d(-4px, 0, 0)', offset: 0.7}),
          style({transform: 'translate3d(2px, 0, 0)', offset: 0.8}),
          style({transform: 'translate3d(-1px, 0, 0)', offset: 0.9}),
        ]))
      )
    ])
  ]
})
export class LoginComponent implements OnInit {

  selectedTabIndex: number = 0;
  showLoader = false;
  isError = null;

  @ViewChild(OrganizationUserComponent) organizationUserComponent: OrganizationUserComponent;
  @ViewChild(StandardUserComponent) standardUserComponent: StandardUserComponent;

  constructor(private _dialog: MatDialog,
    private _login: LoginService,
    private _router: Router,
    private _title: Title) { }

  ngOnInit() {
    const checkId = setInterval(() => {
      if (this._login.isValidating()) {
        clearInterval(checkId);
        if(this._login.isLoggedIn()) {
          this._router.navigate(['/home']);
          return;
        }
      }
    }, 300);
    this._title.setTitle('Firedesk - Einloggen');
  }

  registerOrganization() {
    this._dialog.open(RegisterOrganizationComponent, {
      width: '600px'
    });
  }

  passwordForgot() {
    this._dialog.open(PasswordForgotComponent, {
      data: {
        type: this.selectedTabIndex
      },
      width: '400px'
    });
  }

  submit() {
    switch(this.selectedTabIndex) {
      case 0:
        this.organizationUserComponent.submit().catch(error => {
          this.isError = !this.isError;
        });
        break;
      case 1:
        this.standardUserComponent.submit().catch(error => {
          this.isError = !this.isError;
        });
        break;
    }
  }


  privacyStatement() {
    this._dialog.open(PrivacyComponent, {
      width: '1000px'
    });
  }
}

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.html'
})
export class PrivacyComponent implements OnInit {
  ngOnInit() {
    window.location.hash = 'privacy';
  }
}