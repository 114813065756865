<h1 mat-dialog-title>Neustart?</h1>

<hr />

<div mat-dialog-content>
  Möchten Sie das Master Endgerät neustarten?
</div>

<div mat-dialog-actions>
  <div class="clearfix full-width mt-2">
    <div class="float-end">
      <button
        mat-raised-button
        mat-dialog-close="">
        Abbrechen
      </button>      
      <button
        mat-raised-button
        color="warn"
        mat-dialog-close="true">
        Neustarten
      </button>
    </div>
  </div>
</div>
