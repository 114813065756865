import { Component, Host, OnInit } from '@angular/core';
import { NavbarComponent } from '../navbar.component';

@Component({
  selector: 'app-standard-navbar',
  templateUrl: './standard-navbar.component.html',
  styleUrls: ['./standard-navbar.component.scss']
})
export class StandardNavbarComponent implements OnInit {

  constructor(@Host() public _parent: NavbarComponent) { }

  ngOnInit(): void {
  }

}
