import { environment } from './../../../../environments/environment';
import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-standard-groups',
  templateUrl: './standard-groups.component.html',
  styleUrls: ['./standard-groups.component.scss']
})
export class StandardGroupsComponent implements OnInit {

  displayedColumns = ['name', 'organization', 'actions'];
  dataSource = new MatTableDataSource<any[]>();
  showLoader = false;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private _http: HttpClient,
    private _snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.load();
  }

  load() {
    this.showLoader = true;
    this._http.get<any[]>(`${environment.api_url}/cloud/groups`).subscribe(data => {
      this.dataSource.data = data;
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.showLoader = false;
    });
  }

  toggle(id, event) {
    var state = 0;
    if(event.checked) {
      state = 1;
    }

    this._http.post(`${environment.api_url}/cloud/groups/toggle`, {
      id: id,
      state: state
    }).subscribe(data => {
      var stateDescription = 'deaktiviert';
      if(state == 1){
        stateDescription = 'aktiviert';
      }
      this._snackBar.open(`Alarmierungsgruppe wurde ${stateDescription}!`, 'ok', {
        duration: 3000
      });
      this.load();
    });
  }

}
