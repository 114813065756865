import { Component, ViewChild, OnInit, AfterViewInit } from '@angular/core';
import { FieldType } from '@ngx-formly/material';
import { MatInput } from '@angular/material/input';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, startWith, switchMap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'formly-address-autocomplete-type',
  template: `
    <input matInput
      [matAutocomplete]="auto"
      [formControl]="formControl"
      [formlyAttributes]="field"
      [placeholder]="to.placeholder"
      (keyup)="changeEvent($event)"
      [errorStateMatcher]="errorStateMatcher">
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="to.onValueSelect($event.option.value)">
      <mat-option *ngFor="let value of items" [value]="value.address">
        {{ value.address.street }} - {{ value.address.postalCode }} {{ value.address.city }}
      </mat-option>
    </mat-autocomplete>
  `,
})
export class AddressAutocompleteTypeComponent extends FieldType<FieldTypeConfig> implements OnInit, AfterViewInit {
  @ViewChild(MatInput) formFieldControl: MatInput;

  items = [];
  addressQueryChanged: Subject<string> = new Subject<string>();


  constructor (private _http: HttpClient) {
    super();
  }

  ngOnInit() {
    
  }

  changeEvent(value) {
    this.addressQueryChanged.next(this.formControl.value);
  }

  ngAfterViewInit() {
    this.addressQueryChanged.pipe(
      debounceTime(300),
      distinctUntilChanged()
    ).subscribe(model => {
      this.searchStreet(model);
    });
  }

  searchStreet(searchString) {
    this.items = [];
    this._http.get<any>(`${environment.api_url}/cloud/here?query=${searchString}`).subscribe(data => {
      if (data.suggestions && data.suggestions.length > 0) {
        data.suggestions.forEach(elem => {
          if (elem.address.street) {
            this.items.push(elem);
          }
        });
      }
    });
  }
}
