<div class="clearfix mt-2">
  <h1 class="float-start">Rechnung: #{{ _data.invoice.id }}</h1>
  <h4 class="float-end badge" style="padding-left: 15px; padding-right: 15px;" [ngClass]="batchColor(_data.invoice.status)">
    <span *ngIf="_data.invoice.status == 'paid'">Bezahlt</span>
    <span *ngIf="_data.invoice.status == 'open'">Offen</span>
    <span *ngIf="_data.invoice.status == 'reminder'">Mahnung</span>
    <span *ngIf="_data.invoice.status == 'cancelled'">Storniert</span>
  </h4>
</div>

<hr />

<div class="container">
  <div class="row mb-1" *ngFor="let line of _data.lines">
    <div class="col-1">
      {{ line.qty }}x
    </div>
    <div class="col-7">
      <b>{{ line.name }}</b><br />
      <i>{{ line.description }}</i>
    </div>
    <div class="col-4 text-right">
      <h4 style="margin-bottom: 0px">{{ (line.qty * line.amount).toFixed(2) }} &euro;</h4>
      <span style="color: red;" *ngIf="line.discount > 0">Rabatt <b class="ms-1">-</b> {{ line.discount }} %</span>
    </div>
  </div>
</div>

<hr />

<div class="clearfix">
  <div class="float-end">
    <h3 class="text-right"><b>Gesamt: {{ _data.invoice.total.toFixed(2) }} &euro;</b></h3>
    <button mat-raised-button color="primary" class="full-width"
      (click)="_data.host.invoicePay(_data.invoice)"
      *ngIf="!(_data.invoice.status == 'paid' || _data.invoice.status == 'cancelled')">
      Rechnung bezahlen
      <mat-icon>payment</mat-icon>
    </button>
  </div>
</div>