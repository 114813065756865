import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { LoginService } from 'src/app/services/login/login.service';

@Component({
  selector: 'app-subscriptions',
  templateUrl: './subscriptions.component.html',
  styleUrls: ['./subscriptions.component.scss']
})
export class SubscriptionsComponent implements OnInit {

  accountType = 0;
  packageType = 1;

  constructor(private _login: LoginService,
    private _title: Title) { }

  ngOnInit(): void {
    this._login.userSource.subscribe(user => {
      if(user) {
        this.accountType = user.type;
        if (user.type == 2) {
          this._title.setTitle('Firedesk - Abonements');
        } else {
          this._title.setTitle('Keine Berechtigung!');
        }
      }
    });
  }

}
