import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-message-alert',
  templateUrl: './message-alert.component.html',
  styleUrls: ['./message-alert.component.scss']
})
export class MessageAlertComponent implements OnInit {

  message = '';
  showEmojiPicker = false;

  constructor(public _dialogRef: MatDialogRef<MessageAlertComponent>,
    @Inject(MAT_DIALOG_DATA) public _data) { }

  ngOnInit(): void {
  }

  toggleEmojiPicker() {
    this.showEmojiPicker = !this.showEmojiPicker;
  }

  addEmoji(event) {
    console.log(event);
    this.message = `${this.message}${event.emoji.native}`;
  }

  onConfirm(): void {
    this._dialogRef.close({
      message: this.message
    });
  }

}
