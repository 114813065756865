import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { FormlyFieldConfig } from '@ngx-formly/core';
import * as dayjs from 'dayjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-alarms-organization-details',
  templateUrl: './alarms-organization-details.component.html',
  styleUrls: ['./alarms-organization-details.component.scss']
})
export class AlarmsOrganizationDetailsComponent implements OnInit {

  dayjs = dayjs;
  data: any = null;
  responses = [];
  alarmId = -1;
  inValid = false;

  reloadTimer;

  form = new FormGroup({});
  fields: FormlyFieldConfig[] = [
    {
      fieldGroupClassName: 'row',
      fieldGroup: [
        {
          className: 'col-9',
          key: 'street',
          type: 'address-autocomplete',
          templateOptions: {
            label: 'Straße:',
            required: false,
            onValueSelect: (event) => {
              this.form.get('street').setValue(event.street);
              this.form.get('city').setValue(event.city);
              this.form.get('zipcode').setValue(event.postalCode);
            }
          }
        },
        {
          className: 'col-3',
          key: 'appartment',
          type: 'input',
          templateOptions: {
            label: 'Hausnummer:',
            required: false,
            maxLength: 5,
          },
          expressionProperties: {
            'templateOptions.disabled': '!model.street',
          }
        }
      ]
    },
    {
      fieldGroupClassName: 'row',
      fieldGroup: [
        {
          className: 'col-4',
          key: 'zipcode',
          type: 'input',
          templateOptions: {
            label: 'Postleitzahl:',
            required: false
          },
          expressionProperties: {
            'templateOptions.disabled': '!model.street',
          }
        },
        {
          className: 'col-8',
          key: 'city',
          type: 'input',
          templateOptions: {
            label: 'Ort:',
            required: false
          },
          expressionProperties: {
            'templateOptions.disabled': '!model.street',
          }
        }
      ]
    },
    {
      key: 'keyword',
      type: 'keyword-autocomplete',
      templateOptions: {
        label: 'Stichwort:',
        required: false
      }
    },
    {
      key: 'alarmText',
      type: 'textarea',
      templateOptions: {
        label: 'Alarmmeldung:',
        required: false,
        rows: 10
      }
    }
  ];

  constructor(private _title: Title,
    private _http: HttpClient,
    private _route: ActivatedRoute,
    private _loader: NgxSpinnerService,
    private _snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this._loader.show();
    this._title.setTitle('Firedesk - Alarmierungsdetails');

    this._route.params.subscribe(data => {
      this.alarmId = data.id;
      this.load();
    });
  }

  loadResponse() {
    this._http.get<any>(`${environment.api_url}/cloud/alarms/${this.alarmId}/response`).subscribe(data => {
      this.responses = data;
    }, error => {
      console.error(error);
      this.inValid = true;
    });
  }

  startResponseTimer() {
    this.loadResponse();
    this.reloadTimer = setInterval(() => this.loadResponse(), 7000);
  }

  ngOnDestroy() {
    clearInterval(this.reloadTimer);
  }

  load() {
    if (this.alarmId) {
      this._http.get(`${environment.api_url}/cloud/alarms/${this.alarmId}`).subscribe(data => {
        this.data = data;
        setTimeout(() => {
          this._loader.hide();
        }, 800);
        this.startResponseTimer();
      }, error => {
        console.error(error);
        this.inValid = true;
        this._loader.hide();
      });
    } else {
      this.inValid = true;
    }
  }

  save() {
    this._loader.show();
    this._http.post(`${environment.api_url}/cloud/alarms/${this.alarmId}`, {
      data: this.data
    }).subscribe(data => {
      this._loader.hide();
      this._snackBar.open('Einsatz wurde erfolgreich gespeichert!', 'ok', {
        duration: 3000,
        horizontalPosition: 'end'
      });
    }, error => {
      this._snackBar.open('Beim Speichern des Einsatzes ist ein Fehler aufgetreten!', 'ok', {
        duration: 3000,
        horizontalPosition: 'end'
      });
      setTimeout(() => {
        this._loader.hide();
      }, 600);
    });
  }
}
