import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxSpinnerService } from 'ngx-spinner';
import { LoginService } from '../../../services/login/login.service';

@Component({
  selector: 'app-password-forgot',
  templateUrl: './password-forgot.component.html',
  styleUrls: ['./password-forgot.component.scss']
})
export class PasswordForgotComponent implements OnInit {

  textInput = '';
  showLoader = false;

  constructor(private _dialog: MatDialogRef<PasswordForgotComponent>,
    @Inject(MAT_DIALOG_DATA) public _data,
    private _snackBar: MatSnackBar,
    private _loader: NgxSpinnerService,
    private _login: LoginService) { }

  ngOnInit(): void {
  }

  submit() {
    if (this._data.type == 1) {
      this.showLoader = true;
      this._loader.show();
      this._login.resetPasswordStandard(this.textInput).subscribe((data: any) => {
        this.showLoader = false;
        this._snackBar.open(data.text, 'ok', {
          duration: 3000
        });
        this._loader.hide();
      }, (error: any) => {
        this.showLoader = false;
        this._snackBar.open('Interner Serverfehler! Bitte versuche es später erneut.', 'ok', {
          duration: 3000
        });
        this._loader.hide();
        this._dialog.close();
      });
    }
  }
}
