import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { OrganizationUserComponent, TwoFactorDialogConfirmComponent } from './components/login/organization-user/organization-user.component';
import { StandardUserComponent } from './components/login/standard-user/standard-user.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material';
import { PasswordForgotComponent } from './components/login/password-forgot/password-forgot.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { RegisterOrganizationComponent } from './components/login/register-organization/register-organization.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { HomeComponent } from './components/home/home.component';
import { OrganizationNavbarComponent } from './components/navbar/organization-navbar/organization-navbar.component';
import { StandardNavbarComponent } from './components/navbar/standard-navbar/standard-navbar.component';
import { StandardHomeComponent } from './components/home/standard-home/standard-home.component';
import { EmailValidator } from './validators/email.validator';
import { PasswordMatchValidator } from './validators/password.validator';
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';
import { AlertComponent } from './utils/alert/alert.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { OrganizationHomeComponent } from './components/home/organization-home/organization-home.component';
import { StandardAlarmsComponent } from './components/alarms/standard-alarms/standard-alarms.component';
import { OrganizationAlarmsComponent, OrganizationAlarmsDialogComponent } from './components/alarms/organization-alarms/organization-alarms.component';
import { AlarmsComponent } from './components/alarms/alarms.component';
import { AlarmsKeywordComponent } from './components/alarms/organization-alarms/alarms-keyword/alarms-keyword.component';
import { AlarmsAddressComponent } from './components/alarms/organization-alarms/alarms-address/alarms-address.component';
import { AlarmsGroupsComponent } from './components/alarms/organization-alarms/alarms-groups/alarms-groups.component';
import { HttpAuthInterceptor } from './utils/http-auth-interceptor';
import { AddressAutocompleteTypeComponent } from './types/formly/address-autocomplete-type.component';
import { OrganizationGroupsComponent, OrganizationGroupsPopupComponent } from './components/groups/organization-groups/organization-groups.component';
import { StandardGroupsComponent } from './components/groups/standard-groups/standard-groups.component';
import { GroupsComponent } from './components/groups/groups.component';
import { registerLocaleData } from '@angular/common';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { DevicesComponent } from './components/devices/devices.component';
import { ConfirmAlertComponent } from './utils/confirm-alert/confirm-alert.component';
import { UsersComponent } from './components/users/users.component';
import { MessageAlertComponent } from './utils/message-alert/message-alert.component';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { UserDialogComponent } from './components/users/user-dialog/user-dialog.component';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { GroupUserDialogComponent } from './components/users/group-user-dialog/group-user-dialog.component';
import { EndpointsComponent, EndpointsFilterPopupComponent } from './components/endpoints/endpoints.component';
import { InvoicesComponent } from './components/invoices/invoices.component';
import { InvoicesDetailComponent } from './components/invoices/invoices-detail/invoices-detail.component';
import { InvoicesPayComponent } from './components/invoices/invoices-pay/invoices-pay.component';
import { SubscriptionsComponent } from './components/subscriptions/subscriptions.component';
import { TimeoutsComponent, TimeoutsEditComponent, TimeoutsGroupsEditComponent } from './components/timeouts/timeouts.component';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { KeywordAutocompleteTypeComponent } from './types/formly/keyword-autocomplete-type.component';
import { AlarmsOrganizationDetailsComponent } from './components/alarms/organization-alarms/alarms-organization-details/alarms-organization-details.component';
import { RestartAlertComponent } from './utils/restart-alert/restart-alert.component';
import { EndpointsActionsComponent } from './components/endpoints/endpoints-actions/endpoints-actions.component';
import { EndpointsActionsDetailComponent } from './components/endpoints/endpoints-actions/endpoints-actions-detail/endpoints-actions-detail.component';
import { FormlyFieldStepper } from './types/formly/stepper-type.component';
import { VehicleComponent } from './components/vehicle/vehicle.component';
import { VehicleDialogComponent } from './components/vehicle/vehicle-dialog/vehicle-dialog.component';
import { EndpointsModulesComponent } from './components/endpoints/endpoints-modules/endpoints-modules.component';
import { ProfileComponent } from './components/profile/profile.component';
import { OrganizationProfileComponent, TwoFactorDialogComponent, TwoFactorDisableDialogComponent } from './components/profile/organization-profile/organization-profile.component';
import { StandardProfileComponent } from './components/profile/standard-profile/standard-profile.component';
import { QRCodeModule } from 'angularx-qrcode';
import { VehicleAutocompleteTypeComponent } from './types/formly/vehicle-autocomplete-type.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { UserGroupDialogComponent } from './components/groups/organization-groups/user-group-dialog/user-group-dialog.component';
import { NgxPopperjsModule } from 'ngx-popperjs';
import { PwaService } from './utils/pwa/pwa.service';
import { environment } from '../environments/environment';
import { ServiceWorkerModule } from '@angular/service-worker';
import { PrivacyComponent } from './components/login/privacy/privacy.component';

const germanRangeLabel = (page: number, pageSize: number, length: number) => {
  if (length == 0 || pageSize == 0) { return `0 von ${length}`; }

  length = Math.max(length, 0);

  const startIndex = page * pageSize;

  // If the start index exceeds the list length, do not try and fix the end index to the end.
  const endIndex = startIndex < length ?
      Math.min(startIndex + pageSize, length) :
      startIndex + pageSize;

  return `${startIndex + 1} - ${endIndex} von ${length}`;
}

registerLocaleData(localeDe, localeDeExtra);

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    OrganizationUserComponent,
    StandardUserComponent,
    FormlyFieldStepper,
    RegisterOrganizationComponent,
    PasswordForgotComponent,
    HomeComponent,
    NavbarComponent,
    OrganizationNavbarComponent,
    StandardNavbarComponent,
    StandardHomeComponent,
    AlertComponent,
    OrganizationHomeComponent,
    StandardAlarmsComponent,
    OrganizationAlarmsComponent,
    OrganizationAlarmsDialogComponent,
    AlarmsComponent,
    AlarmsKeywordComponent,
    AlarmsAddressComponent,
    AlarmsGroupsComponent,
    AddressAutocompleteTypeComponent,
    KeywordAutocompleteTypeComponent,
    VehicleAutocompleteTypeComponent,
    OrganizationGroupsComponent,
    StandardGroupsComponent,
    GroupsComponent,
    DevicesComponent,
    ConfirmAlertComponent,
    UsersComponent,
    MessageAlertComponent,
    UserDialogComponent,
    GroupUserDialogComponent,
    EndpointsComponent,
    OrganizationGroupsPopupComponent,
    InvoicesComponent,
    InvoicesDetailComponent,
    InvoicesPayComponent,
    SubscriptionsComponent,
    TimeoutsComponent,
    TimeoutsEditComponent,
    TimeoutsGroupsEditComponent,
    AlarmsOrganizationDetailsComponent,
    RestartAlertComponent,
    EndpointsActionsComponent,
    EndpointsFilterPopupComponent,
    EndpointsActionsDetailComponent,
    VehicleComponent,
    VehicleDialogComponent,
    EndpointsModulesComponent,
    ProfileComponent,
    OrganizationProfileComponent,
    StandardProfileComponent,
    TwoFactorDialogComponent,
    TwoFactorDialogConfirmComponent,
    TwoFactorDisableDialogComponent,
    UserGroupDialogComponent,
    PrivacyComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,
    MaterialModule,
    NgxPopperjsModule,
    ReactiveFormsModule,
    RecaptchaModule,
    FormlyMaterialModule,
    RecaptchaFormsModule,
    PickerModule,
    NgxMaterialTimepickerModule,
    NgxSpinnerModule,
    FormlyModule.forRoot({
      types: [{
        name: 'address-autocomplete',
        component: AddressAutocompleteTypeComponent,
        wrappers: ['form-field'],
      }, {
        name: 'keyword-autocomplete',
        component: KeywordAutocompleteTypeComponent,
        wrappers: ['form-field']
      }, {
        name: 'vehicle-autocomplete',
        component: VehicleAutocompleteTypeComponent,
        wrappers: ['form-field']
      }, { 
        name: 'stepper', 
        component: FormlyFieldStepper, 
        wrappers: [] 
      }],
      validators: [
        { name: 'email', validation: EmailValidator },
        { name: 'passwordMatch', validation: PasswordMatchValidator },
      ],
      validationMessages: [
        { name: 'required', message: 'Dies ist ein Pflichtfeld.' },
        { name: 'email', message: 'Dies ist keine gültige E-Mail Adresse.' }
      ]
    }),
    NgCircleProgressModule.forRoot({
      radius: 20,
      outerStrokeWidth: 8,
      innerStrokeWidth: 8,
      outerStrokeColor: "#0162c9",
      innerStrokeColor: "#fff",
      renderOnClick: false,
      showSubtitle: false,
      animationDuration: 300
    }),
    QRCodeModule,
    NgbModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  providers: [
    CookieService,
    PwaService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpAuthInterceptor,
      multi: true
    },
    { provide: LOCALE_ID, useValue: 'de' },
    { provide: MatPaginatorIntl, useValue: getGermanPaginatorIntl() }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function getGermanPaginatorIntl() {
  const paginatorIntl = new MatPaginatorIntl();

  paginatorIntl.itemsPerPageLabel = 'Einträge pro Seite:';
  paginatorIntl.nextPageLabel = 'Weiter';
  paginatorIntl.previousPageLabel = 'Zurück';
  paginatorIntl.firstPageLabel = "Erste Seite";
  paginatorIntl.lastPageLabel = "Letzte Seite";
  paginatorIntl.getRangeLabel = germanRangeLabel;

  return paginatorIntl;
}
