import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { environment } from 'src/environments/environment';
import { LoginService } from '../../services/login/login.service';

import * as dayjs from 'dayjs';
import { Title } from '@angular/platform-browser';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { InvoicesDetailComponent } from './invoices-detail/invoices-detail.component';
import { InvoicesPayComponent } from './invoices-pay/invoices-pay.component';

@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.scss']
})
export class InvoicesComponent implements OnInit {

  accountType = 0;
  displayedColumns = ['date', 'status', 'total', 'actions'];
  dataSource = new MatTableDataSource<any[]>();
  showLoader = false;
  data = []; 

  env = environment;
  dayjs = dayjs;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(public _login: LoginService,
    private _http: HttpClient,
    private _title: Title,
    private _snackBar: MatSnackBar,
    private _bottom: MatBottomSheet) { }

  ngOnInit(): void {
    this._login.userSource.subscribe(user => {
      if(user) {
        this.accountType = user.type;
        if (user.type == 2) {
          this.load();
        }
      }
    });
    this._title.setTitle("Firedesk - Rechnungen");
  }

  batchColor(status) {
    switch(status) {
      case 'open': return 'badge-danger';
      case 'paid': return 'badge-success';
      case 'cancelled': return 'badge-secondary';
      case 'reminder': return 'badge-warning';
    }
  }

  load() {
    this.showLoader = true;
    this._http.get<any[]>(`${environment.api_url}/cloud/invoices`).subscribe(data => {
      this.dataSource.data = data;
      this.data = data;
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.showLoader = false;
    });
  }

  loadInvoice(elem) {
    this._http.get(`${environment.api_url}/cloud/invoices/${elem.id}/lines`).subscribe(data => {
      this._bottom.open(InvoicesDetailComponent, {
        data: {
          invoice: elem,
          lines: data,
          host: this
        }
      });
    }, error => {
      this._snackBar.open('Fehler beim Abrufen der Rechnung!', 'ok', {
        duration: 3000
      });
    });
  }

  invoicePay(elem) {
    this._bottom.open(InvoicesPayComponent, {
      data: elem
    });
  }
}
