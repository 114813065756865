import { Component, ViewChild, OnInit, AfterViewInit } from '@angular/core';
import { FieldType } from '@ngx-formly/material';
import { MatInput } from '@angular/material/input';
import { Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'formly-keyword-autocomplete-type',
  template: `
    <input matInput
      [matAutocomplete]="auto"
      [formControl]="formControl"
      [formlyAttributes]="field"
      [placeholder]="to.placeholder"
      (keyup)="filterKeywords()"
      [errorStateMatcher]="errorStateMatcher">
    <mat-autocomplete #auto="matAutocomplete" (onSelect)="to.onValueSelect(field,$event)">
      <mat-option *ngFor="let value of items" [value]="value">
        {{ value }}
      </mat-option>
    </mat-autocomplete>
  `,
})
export class KeywordAutocompleteTypeComponent extends FieldType<FieldTypeConfig> implements OnInit {
  @ViewChild(MatInput) formFieldControl: MatInput;

  items = [];
  rawData = [];

  constructor (private _http: HttpClient,
    private _snackBar: MatSnackBar) {
    super();
  }

  ngOnInit() {
    this._http.get(`${environment.api_url}/cloud/keywords`).subscribe((data: any) => {
      this.rawData = data;
    }, error => {
      console.error(error);
      this._snackBar.open('Fehler beim Abrufen der Stichwörter', 'ok', {
        duration: 3000
      });
    });
  }

  filterKeywords() {
    this.items = this.rawData.filter(keyword =>
      keyword.toLowerCase().indexOf(this.formControl.value.toLowerCase()) !== -1);
  }
}
