import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormlyFieldConfig } from '@ngx-formly/core';

@Component({
  selector: 'app-register-organization',
  templateUrl: './register-organization.component.html',
  styleUrls: ['./register-organization.component.scss']
})
export class RegisterOrganizationComponent implements OnInit {

  organizationForm = new FormGroup({});
  contactPersonForm = new FormGroup({});

  recaptcha = false;
  showLoader = false;

  organizationModel = { name: '', type: '1', street: '', zipcode: '', city: '', country: '' };
  contactPersonModel = { email: '', password: '', passwordR: '', privacy: '' };

  organizationFields: FormlyFieldConfig[] = [
    {
      key: 'name',
      type: 'input',
      templateOptions: {
        label: 'Name der Organisation',
        required: true,
      }
    },
    {
      key: 'type',
      type: 'select',
      templateOptions: {
        label: 'Art der Organisation',
        options: [
          {label: 'Feuerwehr', value: '1'},
          {label: 'Rettungsdienst', value: '2'},
          {label: 'Technisches Hilfswerk', value: '3'},
          {label: 'Andere', value: '4'},
        ],
        required: true,
      }
    },
    {
      key: 'street',
      type: 'input',
      templateOptions: {
        label: 'Straße und Hausnummer',
        required: true,
      }
    },
    {
      key: 'zipcode',
      type: 'input',
      templateOptions: {
        label: 'Postleitzahl',
        type: 'number',
        required: true,
      }
    },
    {
      key: 'city',
      type: 'input',
      templateOptions: {
        label: 'Stadt / Ort',
        required: true,
      }
    },
    {
      key: 'country',
      type: 'select',
      templateOptions: {
        label: 'Land',
        options: [
          {label: 'Deutschland', value: 'germany'},
          {label: 'Österreich', value: 'austria'},
          {label: 'Schweiz', value: 'switzerland'},
        ],
        required: true,
      }
    }
  ];
  contactPersonFields: FormlyFieldConfig[] = [
    {
      key: 'email',
      type: 'input',
      templateOptions: {
        label: 'E-Mail',
        type: 'email',
        required: true,
      },
      validators: {
         validation: ['email']
      }
    },
    {
      key: 'password',
      type: 'input',
      templateOptions: {
        label: 'Password',
        type: 'password',
        required: true,
      }
    },
    {
      key: 'passwordR',
      type: 'input',
      templateOptions: {
        label: 'Passwort (wiederholen)',
        type: 'password',
        required: true,
      }
    },
    {
      key: 'privacy',
      type: 'checkbox',
      templateOptions: {
        label: 'Datenschutzerklärung akzeptieren',
        value: 'true',
        required: true,
      }
    }
  ];

  constructor(private _snackBar: MatSnackBar) { }

  ngOnInit(): void {
  }

  resolvedCaptcha(event) {
    this.recaptcha = true;
  }

  successMessage() {
    this._snackBar.open('Ihr Account wurde erfolgreich erstellt, Sie erhalten in kürze ein Bestätigungsmail.', 'schließen', {
      duration: 6000
    });
  }

}
