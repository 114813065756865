<div class="row">
  <div class="col-12">
    <mat-card class="m-3">
      <mat-card-title>
        <div class="clearfix">
          <span class="float-start">
            <mat-icon>shopping_basket</mat-icon>
            Abonements
          </span>
        </div>
      </mat-card-title>

      <mat-card-subtitle>
        In dieser Ansicht können Sie Ihr Abonement verwalten.
      </mat-card-subtitle>

      <mat-card-content>
        <div *ngIf="accountType == 1; else elseBlock;" class="alert alert-danger pt-2">
          <b>Fehler!</b> Für diesen Bereich haben Sie keinen Zugang!
        </div>
        <ng-template #elseBlock>
          <div class="row">
            <div class="col-6">
              <button mat-raised-button
                class="subscription"
                (click)="packageType = 1"
                [color]="packageType == 1 ? 'primary' : 'secondary'">
                Paket: BASIS<br />(0,- € / Monat)
              </button>
            </div>
            <div class="col-6">
              <button mat-raised-button
                class="subscription"
                (click)="packageType = 2"
                [color]="packageType == 2 ? 'primary' : 'secondary'">
                Paket: PRO<br />(15,99 € / Monat)
              </button>
            </div>
          </div>
        </ng-template>
      </mat-card-content>
    </mat-card>
  </div>
</div>
