import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-user-dialog',
  templateUrl: './user-dialog.component.html',
  styleUrls: ['./user-dialog.component.scss']
})
export class UserDialogComponent implements OnInit {

  form = new FormGroup({});

  model = { firstname: '', lastname: '', email: '' };
  fields: FormlyFieldConfig[] = [
    {
      key: 'firstname',
      type: 'input',
      templateOptions: {
        label: 'Vorname:',
        required: true,
      }
    },
    {
      key: 'lastname',
      type: 'input',
      templateOptions: {
        label: 'Nachname:',
        required: true,
      }
    },
    {
      key: 'email',
      type: 'input',
      validators: {
         validation: ['email']
      },
      templateOptions: {
        type: 'email',
        label: 'E-Mail:',
        required: true,
      }
    }
  ];

  constructor(public _dialogRef: MatDialogRef<UserDialogComponent>,
    private _http: HttpClient,
    private _snackBar: MatSnackBar) { }

  ngOnInit(): void {
  }

  save() {
    this._http.post(`${environment.api_url}/cloud/users`, this.model).subscribe(data => {
      this._snackBar.open('Benutzer wurde erfolgreich hinzugefügt.', 'ok', {
        duration: 3000
      });
      this._dialogRef.close(true);
    }, error => {
      this._snackBar.open('Fehler: ' + error.error.text, 'ok', {
        duration: 3000
      });
    })
  }

}
