import { Component, OnInit } from '@angular/core';
import { Welcome } from 'src/app/utils/simple/welcome';
import { LoginService } from '../../../services/login/login.service';

@Component({
  selector: 'app-organization-home',
  templateUrl: './organization-home.component.html',
  styleUrls: ['./organization-home.component.scss']
})
export class OrganizationHomeComponent implements OnInit {

  welcomeText = '';

  constructor(private _login: LoginService) { }

  ngOnInit(): void {
    this._login.userSource.subscribe(user => {
      this.welcomeText = Welcome.generateText(user.name);
    });
  }

}
