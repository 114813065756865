<h1 mat-dialog-title>Zeitschaltung</h1>

<div mat-dialog-content>
  <mat-form-field class="full-width mb-3">
    <mat-label>Name</mat-label>
    <input matInput [(ngModel)]="_data.name" />
    <mat-hint *ngIf="!_data.name" style="color: red">Name muss ausgefüllt werden!</mat-hint>
  </mat-form-field>

  <div class="row mb-3">
    <div class="col-lg-6 col-12">
      <mat-form-field class="full-width mb-3">
        <mat-label>Von</mat-label>
        <input matInput [(ngModel)]="_data.from" [ngxTimepicker]="fromPicker" [format]="24"  readonly />
        <ngx-material-timepicker #fromPicker></ngx-material-timepicker>
        <mat-hint *ngIf="!_data.from" style="color: red">"Von"-Uhrzeit muss ausgefüllt werden!</mat-hint>
      </mat-form-field>
    </div>
    <div class="col-lg-6 col-12">
      <mat-form-field class="full-width">
        <mat-label>Bis</mat-label>
        <input matInput [(ngModel)]="_data.to" [ngxTimepicker]="toPicker" [format]="24"  readonly />
        <ngx-material-timepicker #toPicker></ngx-material-timepicker>
        <mat-hint *ngIf="!_data.to" style="color: red">"Bis"-Uhrzeit muss ausgefüllt werden!</mat-hint>
      </mat-form-field>
    </div>
  </div>

  <mat-form-field class="full-width mb-3">
    <mat-label>Wochentag</mat-label>
    <mat-select [(ngModel)]="_data.dayOfWeek">
      <mat-option *ngFor="let weekDay of weekDayList" [value]="weekDay.value">
        {{weekDay.name}}
      </mat-option>
    </mat-select>
    <mat-hint *ngIf="!_data.dayOfWeek" style="color: red">Wochentag muss ausgewählt werden!</mat-hint>
  </mat-form-field>

  <mat-form-field class="full-width">
    <mat-label>Woche im Monat</mat-label>
    <mat-select [(ngModel)]="_data.weekOfMonth">
      <mat-option *ngFor="let week of weekOfMonthList" [value]="week.value">
        {{week.name}}
      </mat-option>
    </mat-select>
    <mat-hint *ngIf="!_data.weekOfMonth && _data.weekOfMonth != 0" style="color: red">Woche im Monat muss ausgewählt werden!</mat-hint>
  </mat-form-field>
</div>

<div mat-dialog-actions>
  <span class="big-spacer"></span>
  <button mat-button (click)="save()" [disabled]="!_data.to || !_data.from || !_data.name || !_data.dayOfWeek || !(_data.weekOfMonth || _data.weekOfMonth == 0)">Speichern</button>
</div>
