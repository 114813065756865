<div class="row">
  <div class="col-12">
    <mat-card class="m-3">
      <mat-card-title>
        <div class="clearfix">
          <span class="float-start">
            <mat-icon>layers</mat-icon>
            <span class="aligned-with-icon">{{title}}</span>
          </span>
        </div>
      </mat-card-title>

      <mat-card-content>
        <div *ngIf="accountType == 1; else elseBlock;" class="alert alert-danger pt-2">
          <b>Fehler!</b> Für diesen Bereich haben Sie keinen Zugang!
        </div>

        <ng-template #elseBlock>
          <form [formGroup]="form">
            <formly-form [form]="form" [fields]="fields" [model]="model"></formly-form>
          </form> 
        </ng-template>
      </mat-card-content>
    </mat-card>
  </div>
</div>

<a mat-fab color="primary" id="backButton" href="javascript:window.history.back();">
  <mat-icon>reply</mat-icon>
</a>


<mat-progress-bar
    *ngIf="showLoader"
    mode="query"
    class="login-spinner"></mat-progress-bar>
