<h1 mat-dialog-title>Gruppe {{ _data ? 'bearbeiten' : 'erstellen' }}</h1>

<div mat-dialog-content>
  <mat-form-field class="full-width">
    <mat-label>Name</mat-label>
    <input matInput [(ngModel)]="_data.name" />
    <mat-hint *ngIf="!_data.name" style="color: red">Dies ist ein Pflichtfeld.</mat-hint>
  </mat-form-field>
</div>

<div mat-dialog-actions>
  <span class="big-spacer"></span>
  <button mat-button (click)="save()" [disabled]="!_data.name">Speichern</button>
</div>
