import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { LoginService } from 'src/app/services/login/login.service';
import { ConfirmAlertComponent } from 'src/app/utils/confirm-alert/confirm-alert.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-endpoints-actions',
  templateUrl: './endpoints-actions.component.html',
  styleUrls: ['./endpoints-actions.component.scss']
})
export class EndpointsActionsComponent implements OnInit {

  accountType = 0;

  displayedColumns = ['name', 'trigger', 'active', 'actions'];
  dataSource = new MatTableDataSource<any[]>();
  showLoader = false;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(public _login: LoginService,
    private _title: Title,
    private _http: HttpClient,
    private _snackBar: MatSnackBar,
    private _dialog: MatDialog) { }

  ngOnInit(): void {
    this._login.userSource.subscribe(user => {
      if(user) {
        this.accountType = user.type;
        if (user.type == 2) {
          this.load();
        }
      }
    });
    this._title.setTitle("Firedesk - Aktionen");
  }

  load() {
    this.showLoader = true;
    this._http.get<any[]>(`${environment.api_url}/cloud/gateways/actions`).subscribe(data => {
      this.dataSource.data = data;
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.showLoader = false;
    });
  }

  deleteAction(elem) {
    let dialogRef = this._dialog.open(ConfirmAlertComponent, {
      data: {
        title: 'Wirklich löschen?',
        message: 'Soll diese Aktion wirklich gelöscht werden?'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        
      }
    });
  }

  toggleAction(id, event) {
    let state = 0;
    if(event.checked) state = 1;
    this._http.post(`${environment.api_url}/cloud/gateways/actions/toggle`, {
      id: id,
      state: state
    }).subscribe(data => {}, error => {
      this._snackBar.open('Fehler beim Speichern des Aktions Status!', 'ok', {
        duration: 3000
      });
    });
  }

  editAction(elem) {

  }

}
