import { Component } from '@angular/core';
import { FieldType, FieldTypeConfig, FormlyFieldConfig } from '@ngx-formly/core';

@Component({
  selector: 'formly-field-stepper',
  template: `
  <mat-horizontal-stepper [linear]="true">
    <mat-step
      *ngFor="let step of field.fieldGroup; let index = index; let last = last;"
      [completed]="isValid(step)">
      <ng-template matStepLabel>{{ step.templateOptions.label }}</ng-template>
      <formly-field class="mt-2" [field]="step"></formly-field>

      <div class="clearfix">
        <button matStepperPrevious *ngIf="index !== 0"
          class="float-start"
          mat-button>
          Zurück
        </button>

        <button matStepperNext *ngIf="!last"
          class="float-end"
          mat-button
          [disabled]="!isValid(step)">
          Weiter
        </button>

        <button *ngIf="last"
          class="float-end"
          mat-raised-button
          color="primary"
          [disabled]="!form.valid"
          type="submit">
          Speichern
          <mat-icon>save</mat-icon>
        </button>
      </div>
    </mat-step>
  </mat-horizontal-stepper>
`,
})
export class FormlyFieldStepper extends FieldType<FieldTypeConfig> {
  isValid(field: FormlyFieldConfig) {
    if (field.key) {
      return field.formControl.valid;
    }

    return field.fieldGroup.every(f => this.isValid(f));
  }
}