<div class="row h-100">
  <div class="col-lg-4 col-12 offset-lg-4  my-auto">
    <div class="text-center align-middle mt-3">
      <img src="../../assets/logo.png" width="120" />
    </div>
    <mat-card class="m-2" [@shake]="isError">
      <mat-tab-group mat-stretch-tabs [(selectedIndex)]="selectedTabIndex">
        <mat-tab label="Organisation">
          <app-organization-user class="m-2"></app-organization-user>
        </mat-tab>
        <mat-tab label="Endbenutzer">
          <app-standard-user class="m-2"></app-standard-user>
        </mat-tab>
      </mat-tab-group>
      <div class="text-center align-middle mt-1">
        <button
          mat-raised-button
          (click)="submit()"
          color="accent">Einloggen</button>
        <button
          mat-raised-button color="primary"
          class="ms-2"
          (click)="registerOrganization()"
          [disabled]="selectedTabIndex != 0"
          matTooltip="Endbenutzer Registrierung nur per App möglich"
          matTooltipPosition="above"
          [matTooltipDisabled]="selectedTabIndex == 0">Registrieren</button><br />
          <div class="row">
            <div class="col-lg-12 col-6">
              <button
                mat-button
                color="warn"
                class="mt-3"
                (click)="passwordForgot()">Passwort vergessen?</button>
            </div>
            <div class="col-lg-12 col-6">
              <button
                mat-button
                color="secondary"
                class="mt-3"
                routerLink="/privacy">Datenschutzerklärung</button>
            </div>
          </div>
        <mat-progress-bar
          *ngIf="showLoader"
          mode="query"
          class="login-spinner"></mat-progress-bar>
      </div>
    </mat-card>
  </div>
</div>