<h1 mat-dialog-title><mat-icon>fiber_new</mat-icon> Neuen Einsatz anlegen</h1>
<div mat-dialog-content>
  <mat-horizontal-stepper #stepper>
    <mat-step>
      <ng-template matStepLabel>Einsatzinfos</ng-template>
      <app-alarms-keyword></app-alarms-keyword>
    </mat-step>
    <mat-step>
      <ng-template matStepLabel>Adresse</ng-template>
      <app-alarms-address></app-alarms-address>
    </mat-step>
    <mat-step>
      <ng-template matStepLabel>Gruppen</ng-template>
      <app-alarms-groups></app-alarms-groups>
    </mat-step>
  </mat-horizontal-stepper>
</div>
<div mat-dialog-actions align="end">
  <button
    mat-raised-button
    class="me-2"
    color="red"
    (click)="safeAlarm()"
    [disabled]="safeAlarmed || (!this.address_comp || !this.address_comp.form.valid || !this.groups_comp || !this.groups_comp.someSelected || !this.keyword_comp || !this.keyword_comp.form.valid)">Sicher alarmieren <mat-icon>play_arrow</mat-icon></button>
  <button
    mat-raised-button
    color="primary"
    (click)="sendAlarm()"
    [disabled]="!safeAlarmed">Alarmieren <mat-icon>report_problem</mat-icon></button>
</div>
