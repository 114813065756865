<mat-card-title>
  <mat-icon>home</mat-icon>
  <span class="aligned-with-icon">{{welcomeText}}</span>
</mat-card-title>

<mat-card-subtitle>
  <i>Im Firedesk Cloud Bereich kannst du diverse Einstellungen vornehmen, schaue dich um.</i>
</mat-card-subtitle>

<mat-card-content>
  <div class="row">
    <div class="col-lg-6 pt-2 text-center">
      <h2>Geräte Registriert</h2>
      <circle-progress
        [percent]="(deviceCount / 2) * 100"
        [radius]="100"
        [showUnits]="false"
        [title]="deviceCount"
      ></circle-progress>
    </div>
    <div class="col-lg-6 pt-2 text-center">
      <h2>Einsätze gesamt</h2>
      <circle-progress
        [percent]="100"
        [radius]="100"
        [showUnits]="false"
        [title]="deviceCount"
      ></circle-progress>
    </div>
  </div>
</mat-card-content>
