<mat-card-title><mat-icon>group</mat-icon> Meine Alarmierungsgruppen</mat-card-title>

<mat-card-subtitle>
  <i>In dieser Ansicht kannst du Alarmierungsgruppen aktivieren oder deaktivieren,
  beispielsweise falls du außerorts oder derzeit keine Schichten hast.</i>
</mat-card-subtitle>

<mat-card-content>
  <div class="alert alert-info" *ngIf="dataSource.data.length == 0">
    <mat-icon>info</mat-icon> <span class="aligned-with-icon">Derzeit bist du keinen Gruppen zugeordnet.</span>
  </div>

  <div class="table-responsive">
    <table mat-table [dataSource]="dataSource" matSort class="pt-2 full-width">
      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
        <td mat-cell *matCellDef="let element"> {{element.name}} </td>
      </ng-container>
  
      <!-- Organization Column -->
      <ng-container matColumnDef="organization">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Organisation </th>
        <td mat-cell *matCellDef="let element"> {{element.organization}} </td>
      </ng-container>
  
      <!-- A ction Column -->
      <ng-container matColumnDef="actions" justify="end">
        <th mat-header-cell *matHeaderCellDef>
          Aktiv
          <button mat-icon-button (click)="load()">
            <mat-icon>refresh</mat-icon>
          </button>
        </th>
        <td mat-cell *matCellDef="let element">
          <mat-slide-toggle
            [checked]="element.enabled == 1"
            (change)="toggle(element.id, $event)"></mat-slide-toggle>
        </td>
      </ng-container>
  
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
  <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
</mat-card-content>

<mat-progress-bar
    *ngIf="showLoader"
    mode="query"
    class="login-spinner"></mat-progress-bar>
