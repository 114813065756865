import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { HomeComponent } from './components/home/home.component';
import { AlarmsComponent } from './components/alarms/alarms.component';
import { GroupsComponent } from './components/groups/groups.component';
import { DevicesComponent } from './components/devices/devices.component';
import { UsersComponent } from './components/users/users.component';
import { EndpointsComponent } from './components/endpoints/endpoints.component';
import { InvoicesComponent } from './components/invoices/invoices.component';
import { SubscriptionsComponent } from './components/subscriptions/subscriptions.component';
import { TimeoutsComponent } from './components/timeouts/timeouts.component';
import { AlarmsOrganizationDetailsComponent } from './components/alarms/organization-alarms/alarms-organization-details/alarms-organization-details.component';
import { EndpointsActionsComponent } from './components/endpoints/endpoints-actions/endpoints-actions.component';
import { EndpointsActionsDetailComponent } from './components/endpoints/endpoints-actions/endpoints-actions-detail/endpoints-actions-detail.component';
import { VehicleComponent } from './components/vehicle/vehicle.component';
import { EndpointsModulesComponent } from './components/endpoints/endpoints-modules/endpoints-modules.component';
import { ProfileComponent } from './components/profile/profile.component';
import { PrivacyComponent } from './components/login/privacy/privacy.component';


const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'home' },
  { path: 'login',  component: LoginComponent },
  { path: 'profile',  component: ProfileComponent },
  { path: 'home',  component: HomeComponent },
  { path: 'alarms',  component: AlarmsComponent },
  { path: 'alarms/:id',  component: AlarmsOrganizationDetailsComponent },
  { path: 'groups',  component: GroupsComponent },
  { path: 'users',  component: UsersComponent },
  { path: 'vehicles',  component: VehicleComponent },
  { path: 'devices',  component: DevicesComponent },
  { path: 'endpoints',  component: EndpointsComponent },
  { path: 'endpoints/actions',  component: EndpointsActionsComponent },
  { path: 'endpoints/modules',  component: EndpointsModulesComponent },
  { path: 'endpoints/actions/new',  component: EndpointsActionsDetailComponent },
  { path: 'endpoints/actions/:id',  component: EndpointsActionsDetailComponent },
  { path: 'invoices',  component: InvoicesComponent },
  { path: 'subscriptions',  component: SubscriptionsComponent },
  { path: 'timeouts',  component: TimeoutsComponent },
  { path: 'privacy',  component: PrivacyComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
