<a mat-list-item routerLink="/home" routerLinkActive="active" (click)="_parent.drawer.toggle()">
  <mat-icon>home</mat-icon>
  <span class="nav-caption ms-1">Startseite</span>
</a>
<a mat-list-item routerLink="/users" routerLinkActive="active" (click)="_parent.drawer.toggle()">
  <mat-icon>person</mat-icon>
  <span class="nav-caption ms-1">Benutzerverwaltung</span>
</a>
<a mat-list-item routerLink="/groups" routerLinkActive="active" (click)="_parent.drawer.toggle()">
  <mat-icon>group</mat-icon>
  <span class="nav-caption ms-1">Gruppenverwaltung</span>
</a>
<a mat-list-item routerLink="/vehicles" routerLinkActive="active" (click)="_parent.drawer.toggle()">
  <mat-icon>directions_car</mat-icon>
  <span class="nav-caption ms-1">Fahrzeugverwaltung</span>
</a>
<a mat-list-item routerLink="/alarms" routerLinkActive="active" (click)="_parent.drawer.toggle()">
  <mat-icon>notification_important</mat-icon>
  <span class="nav-caption ms-1">Einsatzverwaltung</span>
</a>
<a mat-list-item routerLink="/timeouts" routerLinkActive="active" (click)="_parent.drawer.toggle()">
  <mat-icon>volume_off</mat-icon>
  <span class="nav-caption ms-1">Zeitschaltung</span>
</a>
<!-- <a mat-list-item routerLink="/endpoints" routerLinkActive="active" (click)="_parent.drawer.toggle()">
  <mat-icon>language</mat-icon>
  <span class="nav-caption ms-1">Endpunkte & Aktionen</span>
</a>
<a mat-list-item routerLink="/subscriptions" routerLinkActive="active" (click)="_parent.drawer.toggle()">
  <mat-icon>shopping_basket</mat-icon>
  <span class="nav-caption ms-1">Abonements</span>
</a>
<a mat-list-item routerLink="/invoices" routerLinkActive="active" (click)="_parent.drawer.toggle()">
  <mat-icon>email</mat-icon>
  <span class="nav-caption ms-1">Rechnungen</span>
</a> -->
