<h1 mat-dialog-title>Nachricht senden an {{_data.name}}</h1>

<hr />

<div mat-dialog-content>
  <mat-form-field class="full-width" appereance="outline">
    <mat-label>Nachricht:</mat-label>
    <textarea
      matInput
      #textbox
      [(ngModel)]="message"
      style="min-height: 200px"></textarea>
  </mat-form-field>
</div>

<div mat-dialog-actions>
  <span class="big-spacer"></span>
  <button
    mat-raised-button
    color="primary"
    [disabled]="message == ''"
    (click)="onConfirm()">
    Senden
    <mat-icon>send</mat-icon>
  </button>
</div>
