import { Component } from '@angular/core';
import { LoginService } from './services/login/login.service';
import { PwaService } from './utils/pwa/pwa.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(public _login: LoginService,
    private Pwa: PwaService) {

  }
}
