import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { LoginService } from 'src/app/services/login/login.service';
import { environment } from 'src/environments/environment';
import { ConfirmAlertComponent } from '../../utils/confirm-alert/confirm-alert.component';
import { MessageAlertComponent } from '../../utils/message-alert/message-alert.component';
import { UserDialogComponent } from './user-dialog/user-dialog.component';
import { GroupUserDialogComponent } from './group-user-dialog/group-user-dialog.component';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {

  accountType = 0;
  displayedColumns = ['name', 'email', 'actions'];
  dataSource = new MatTableDataSource<any[]>();
  showLoader = false;
  data = [];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private _login: LoginService,
    private _http: HttpClient,
    private _dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private _title: Title) { }

  ngOnInit(): void {
    this._login.userSource.subscribe(user => {
      if(user) {
        this.accountType = user.type;

        if(this.accountType == 2) {
          this.load();
        }
      }
    });
    this._title.setTitle('Firedesk - Benutzer');
  }

  search(term: string) {
    if(!term) {
      this.dataSource.data = this.data;
    } else {
      this.dataSource.data = this.data.filter(x =>
         x.name.trim().toLowerCase().includes(term.trim().toLowerCase())
      );
    }
  }

  load() {
    this.showLoader = true;
    this._http.get<any[]>(`${environment.api_url}/cloud/users`).subscribe(data => {
      this.dataSource.data = data;
      this.data = data;
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.showLoader = false;
    });
  }

  editGroups(id) {
    const dialogRef = this._dialog.open(GroupUserDialogComponent, {
      width: '800px',
      data: {
        id: id
      }
    });
  }

  sendPush(user) {
    const dialogRef = this._dialog.open(MessageAlertComponent, {
      width: '800px',
      data: {
        name: user.name
      }
    });

    dialogRef.afterClosed().subscribe(data => {
      if(data.message) {
        this._http.post(`${environment.api_url}/cloud/users/send`, {
          id: user.id,
          message: data.message
        }).subscribe(data => {
          this._snackBar.open('Nachricht wurde gesendet!', 'ok', {
            duration: 3000
          });
        });
      }
    });
  }

  createUser() {
    const dialogRef = this._dialog.open(UserDialogComponent, {
      width: '500px'
    });

    dialogRef.afterClosed().subscribe(data => {
      this.showLoader = true;
      this.load();
    });
  }

  delete(user) {
    const dialogRef = this._dialog.open(ConfirmAlertComponent, {
      data: {
        title: 'Löschen?',
        message: `Möchten Sie den Benutzer: <b>${user.name}</b> löschen?`
      }
    });

    dialogRef.afterClosed().subscribe(status => {
      if(status) {
        this._http.delete(`${environment.api_url}/cloud/users/${user.id}`).subscribe(data => {
          this.load();
          this._snackBar.open('Benutzer wurde erfolgreich gelöscht.', 'ok', {
            duration: 3000
          });
        }, error => {
          this._snackBar.open('Ein Serverfehler ist aufgetreten!', 'ok', {
            duration: 3000
          });
        });
      }
    });
  }
}
