import { MatDialog } from '@angular/material/dialog';
import { ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { LoginService } from '../../services/login/login.service';
import { ConfirmAlertComponent } from '../../utils/confirm-alert/confirm-alert.component';
import { Router } from '@angular/router';
import {Location} from '@angular/common';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  accountType = 0;

  constructor(public _login: LoginService,
    private _dialog: MatDialog,
    private _location: Location,
    private _router: Router) {
  }

  @ViewChild("drawer", { static: false }) drawer: any;

  ngOnInit(): void {
    if (this._location.path() == '/privacy') {
      return;
    }
    this._login.validateToken().then((data: any) => {
    }, error => {
      this._router.navigate(['/login']);
    });

    this._login.userSource.subscribe(user => {
      if(user) {
        this.accountType = user.type;
      }
    });
  }

  closeDrawer() {
    this.drawer.close();
  }

  logout() {
    const dialogRef = this._dialog.open(ConfirmAlertComponent, {
      data: {
        title: 'Ausloggen?',
        message: 'Soll die Sitzung beendet werden?'
      }
    });
    dialogRef.afterClosed().subscribe(status => {
      if(status) {
        this._login.logout();
      }
    });
  }


}
