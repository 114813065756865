<mat-card-title>
  <div class="clearfix">
    <span class="float-start">
      <mat-icon>notification_important</mat-icon>
      Einsätze / Alarmierungen
    </span>
    <div class="float-end">
      <div class="float-start me-2">
        <div class="dropdown">
          <button
            mat-raised-button
            color="gray"
            matTooltip="Filter"
            class="dropdown-toggle"
            data-toggle="dropdown" 
            aria-expanded="false"
            id="btn_filterDropdown">
            <mat-icon>filter_alt</mat-icon>
          </button>
          <div class="dropdown-menu dropdown-menu-end" aria-labelledby="btn_filterDropdown">
            <mat-form-field appearance="fill">
              <mat-label>Zeitraum</mat-label>
              <mat-date-range-input [rangePicker]="picker">
                <input matStartDate placeholder="Startdatum">
                <input matEndDate placeholder="Enddatum">
              </mat-date-range-input>
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-date-range-picker #picker></mat-date-range-picker>
            </mat-form-field>          
          </div>
        </div>
      </div>


      <button
        color="primary"
        (click)="createAlarm()"
        mat-raised-button>
          Neuer Einsatz
          <mat-icon>add_to_photos</mat-icon>
        </button>
    </div>
  </div>
</mat-card-title>

<mat-card-subtitle>
  In dieser Ansicht können Sie Ihre Einsätze verwalten und einen neuen Einsatz anlegen
</mat-card-subtitle>

<mat-card-content>
  <div class="alert alert-info" *ngIf="dataSource.data.length == 0">
    <mat-icon>info</mat-icon> <span class="aligned-with-icon">Derzeit sind keine Alarmierungen vorhanden.</span>
  </div>

  <table mat-table [dataSource]="dataSource" matSort class="pt-2 full-width">
    <!-- Date Column -->
    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Datum </th>
      <td mat-cell *matCellDef="let element"> {{dayjs(element.date).format('DD.MM.YYYY HH:mm') }} </td>
    </ng-container>

    <!-- Keyword Column -->
    <ng-container matColumnDef="keyword">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Stichwort </th>
      <td mat-cell *matCellDef="let element"> {{ element.keyword }} </td>
    </ng-container>

    <!-- A ction Column -->
    <ng-container matColumnDef="actions" justify="end">
      <th mat-header-cell *matHeaderCellDef>
        <button mat-icon-button (click)="load()">
          <mat-icon>refresh</mat-icon>
        </button>
      </th>
      <td mat-cell *matCellDef="let element">
        <button
          mat-icon-button
          [routerLink]="['/alarms/', element.id]"
          matTooltip="Details">
          <mat-icon>remove_red_eye</mat-icon>
        </button>
        <button
          mat-icon-button
          (click)="sendAgain(element.id)"
          matTooltip="Erneut senden">
          <mat-icon>refresh</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
  <ngx-spinner [fullScreen]="false" type="ball-scale-multiple" size="medium"></ngx-spinner>
</mat-card-content>
