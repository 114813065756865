import * as dayjs from 'dayjs';

export class Welcome {
  public static generateText(name = ''): string {
    const date = dayjs();

    var text = 'Gute Nacht';

    if(date.hour() >= 17 && date.hour() <= 22) {
      text = 'Guten Abend';
    } else if(date.hour() >= 14 && date.hour() < 17) {
      text = 'Guten Nachmittag';
    } else if(date.hour() >= 11 && date.hour() < 14) {
      text = 'Guten Tag';
    } else if(date.hour() >= 6 && date.hour() < 11) {
      text = 'Guten Morgen';
    }

    if (name == '') {
       return `${text}!`;
    }

    return `${text}, ${name}!`;
  }
}
