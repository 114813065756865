import { environment } from 'src/environments/environment';
import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { HttpClient } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { of } from 'rxjs';

@Component({
  selector: 'app-alarms-keyword',
  templateUrl: './alarms-keyword.component.html',
  styleUrls: ['./alarms-keyword.component.scss']
})
export class AlarmsKeywordComponent implements OnInit {

  form = new FormGroup({});
  data = [];
  rawData = [];

  model = { keyword: '', message: '' };
  fields: FormlyFieldConfig[] = [{
    key: 'keyword',
    type: 'keyword-autocomplete',
    templateOptions: {
      label: 'Stichwort:',
      required: false,
    }
  },
  {
    key: 'message',
    type: 'textarea',
    templateOptions: {
      label: 'Alarmmeldung:',
      required: false,
      rows: 5
    }
  }];

  filterKeywords(name: string) {
    return this.data.filter(keyword =>
      keyword.toLowerCase().indexOf(name.toLowerCase()) !== -1);
  }

  constructor(private _http: HttpClient,
    private _snackBar: MatSnackBar) { }

  ngOnInit(): void {
    
  }

  processData() {
    this.rawData.forEach(elem => {
      this.data.push(elem.label);
    });
  }

}
