import { environment } from './../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { UserDialogComponent } from '../user-dialog/user-dialog.component';

@Component({
  selector: 'app-group-user-dialog',
  templateUrl: './group-user-dialog.component.html',
  styleUrls: ['./group-user-dialog.component.scss']
})
export class GroupUserDialogComponent implements OnInit {

  displayedColumns = ['name', 'action'];
  dataSource = new MatTableDataSource<any[]>();
  showLoader = false;
  data = [];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(public _dialogRef: MatDialogRef<UserDialogComponent>,
    private _http: HttpClient,
    private _snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) private _data) { }

  ngOnInit(): void {
    this.load();
  }

  load() {
    this._http.get<any[]>(`${environment.api_url}/cloud/users/${this._data.id}/groups`).subscribe(data => {
      this.data = data;
      this.dataSource.data = data;
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    }, error => {
      this._snackBar.open('Fehler beim Laden der Gruppenzuweisungen!', 'ok', {
        duration: 3000
      });
    });
  }

  toggle(id, event) {
    let added = 0;
    if(event.checked) added = 1;
    console.log(this.data);
    this._http.post(`${environment.api_url}/cloud/users/${this._data.id}/groups/${id}`, {
      added: added
    }).subscribe(data => {}, error => {
      this._snackBar.open('Fehler beim Speichern der Gruppenzuweisung!', 'ok', {
        duration: 3000
      });
    });
  }
}
