import { Component, Host, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { LoginComponent } from '../login.component';
import { LoginService } from '../../../services/login/login.service';
import { AlertComponent } from 'src/app/utils/alert/alert.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-organization-user',
  templateUrl: './organization-user.component.html',
  styleUrls: ['./organization-user.component.scss']
})
export class OrganizationUserComponent implements OnInit {

  form = new FormGroup({});

  model = { id: '', password: '' };
  fields: FormlyFieldConfig[] = [
    {
      key: 'id',
      type: 'input',
      templateOptions: {
        label: 'Organisation-ID',
        required: true,
        keyup: (field, event) => {
          if (event.keyCode == 13) {
            this.submit();
          }
        }
      }
    },
    {
      key: 'password',
      type: 'input',
      templateOptions: {
        label: 'Passwort',
        type: 'password',
        required: true,
        keyup: (field, event) => {
          if (event.keyCode == 13) {
            this.submit();
          }
        }
      }
    }
  ];

  constructor(@Host() private _parent: LoginComponent,
    private _login: LoginService,
    private _router: Router,
    private _snackBar: MatSnackBar,
    private _dialog: MatDialog) { }

  ngOnInit() {
  }

  submit(code = '') {
    return new Promise((resolve, reject) => {
      this.form.markAllAsTouched();
      if (!this.form.valid) {
        return reject();
      }

      this._parent.showLoader = true;

      this._login.validateCredentials(this.model.id, this.model.password, code, 2).then(data => {
        this._parent.showLoader = false;
        this._login.setLoggedIn(true);
        this._login.validateToken().then(data => {
          this._router.navigate(['/home']);
        }).catch();
        resolve(true);
      }).catch(error => {
        if (error == '2FA') {
          resolve(true);
          this.show2FA();
          return;
        }
        this._parent.showLoader = false;
        reject();
        this._snackBar.open(error, 'ok', {
          duration: 3000
        });
      });
    });
  }

  show2FA() {
    const dialogRef = this._dialog.open(TwoFactorDialogConfirmComponent, {
      width: '400px'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (!result?.code) {
        this._parent.showLoader = false;
        return;
      }
      this.submit(result.code);
    })
  }
}

@Component({
  selector: 'app-two-factor-dialog-confirm',
  templateUrl: './two-factor-dialog-confirm.html'
})
export class TwoFactorDialogConfirmComponent implements OnInit {
  code = '';

  constructor(private _dialogRef: MatDialogRef<TwoFactorDialogConfirmComponent>) {}

  ngOnInit(): void {
    
  }

  confirm() {
    if (this.code.length == 6) {
      this._dialogRef.close({
        code: this.code
      });
    }
  }

}
