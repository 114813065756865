import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { environment } from 'src/environments/environment';
import { UserDialogComponent } from '../../users/user-dialog/user-dialog.component';

@Component({
  selector: 'app-vehicle-dialog',
  templateUrl: './vehicle-dialog.component.html',
  styleUrls: ['./vehicle-dialog.component.scss']
})
export class VehicleDialogComponent implements OnInit {

  form = new FormGroup({});

  model = { name: '', issi: '' };
  fields: FormlyFieldConfig[] = [
    {
      key: 'name',
      type: 'input',
      templateOptions: {
        label: 'Fahrzeugname:',
        required: true,
      }
    },
    {
      key: 'issi',
      type: 'input',
      templateOptions: {
        label: 'Funkgerät ISSI:',
        required: false,
      }
    }
  ];

  constructor(public _dialogRef: MatDialogRef<UserDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public _data,
    private _http: HttpClient,
    private _snackBar: MatSnackBar) { }

  ngOnInit(): void {
    if (this._data) {
      this.model = JSON.parse(JSON.stringify(this._data));
    }
  }

  save() {
    this._http.post(`${environment.api_url}/cloud/vehicles`, this.model).subscribe(data => {
      this._snackBar.open('Fahrzeug wurde erfolgreich hinzugefügt.', 'ok', {
        duration: 3000
      });
      this._dialogRef.close(true);
    }, error => {
      this._snackBar.open('Fehler: ' + error.error.text, 'ok', {
        duration: 3000
      });
    })
  }

}
