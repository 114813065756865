<h1 mat-dialog-title>Suchergebnisse filtern</h1>

<hr />

<div mat-dialog-content>
  <mat-form-field class="full-width">
    <mat-label>Suche</mat-label>
    <input matInput [(ngModel)]="_data.q" />
  </mat-form-field>
  <mat-form-field class="full-width">
    <mat-label>Zeitraum</mat-label>
    <mat-select [(ngModel)]="_data.timeFrame">
      <mat-option value="3600">1 Stunde</mat-option>
      <mat-option value="21600">6 Stunden</mat-option>
      <mat-option value="86400">1 Tag</mat-option>
      <mat-option value="259200">3 Tage</mat-option>
      <mat-option value="604800">7 Tage</mat-option>
    </mat-select>
  </mat-form-field>
</div>

<div mat-dialog-actions>
  <div class="clearfix full-width mt-2">
    <div class="float-end">   
      <button
        mat-raised-button
        color="primary"
        mat-dialog-close="true">
        schließen
      </button>
    </div>
  </div>
</div>
