<h2 mat-dialog-title>2-Faktor deaktivieren</h2>

<mat-dialog-content>
  <i>Um 2-Faktor Authentifizierung für Ihr Konto zu deaktivieren, geben Sie zur Bestätigung Ihr Kontopasswort ein.</i>
  <br />

  <mat-form-field class="full-width mt-4">
    <mat-label>Passwort eingeben</mat-label>
    <input matInput type="password" [(ngModel)]="password" />
    <mat-hint style="color: red" *ngIf="!password">Bitte geben Sie Ihr Passwort ein!</mat-hint>
  </mat-form-field>

  <button
    mat-raised-button
    color="primary"
    class="float-end mt-3"
    (click)="confirm()"
    [disabled]="!password">
    2-Faktor deaktivieren
  </button>
</mat-dialog-content>