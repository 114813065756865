import { environment } from './../../../..//src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { User } from '../../types/user';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  private loggedIn = false;
  private validating = false;
  private user: User = null;
  public userSource = new BehaviorSubject<User>(this.user);

  constructor(private _http: HttpClient,
    private _snackBar: MatSnackBar,
    private _router: Router,
    private _cookie: CookieService) { }

  isLoggedIn() {
    return this.loggedIn;
  }

  isValidating() {
    return this.validating;
  }

  getToken() {
    return this._cookie.get('token');
  }

  resetPasswordStandard(email: string) {
    return this._http.post(`${environment.api_url}/account/forgot/request`, {
      email: email
    });
  }

  validateCredentials(username, password, code = '', type) {
    return new Promise((resolve, reject) => {
      this._http.post<any>(`${environment.api_url}/cloud/login`, {
        username: username,
        password: password,
        twoFactor: code,
        type: type
      }).subscribe(data => {
        const sessionId = data.text;
        this._cookie.set('token', sessionId, 1);
        this.validateToken();
        resolve(true);
      }, error => {
        reject(error.error.text);
      });
    });
  }

  validateToken() {
    this.validating = true;
    return new Promise((resolve, reject) => {
      const token = this.getToken();

      if(!token) {
        this.validating = false;
        return reject();
      }

      this._http.post<any>(`${environment.api_url}/cloud/validate`, {
        token: token
      }).subscribe(data => {
        const user = data.text as User;
        this.userSource.next(user);
        this.setLoggedIn(true);
        resolve(data.text);
        setTimeout(() => {
          this.validating = false;
        }, 800);
      }, error => {
        this._snackBar.open('Ein Fehler ist beim Login aufgetreten!', 'ok', {
          duration: 2000
        });
        this._router.navigate(['/login']);
        this.validating = false;
        reject();
      });
    });
  }

  validateLogin() {
    this.validating = true;
  }

  logout() {
    this.userSource.next(null);
    this.validating = true;
    this._cookie.delete('token');
    this.setLoggedIn(false);
    this._router.navigate(['/login']);
    setTimeout(() => {
      this.validating = false;
    }, 800);
  }

  setLoggedIn(loggedIn) {
    this.loggedIn = loggedIn;
  }
}
