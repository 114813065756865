<h2 class="text-center">Rechnung bezahlen<br />(Gesamt: {{ _data.total }} &euro;)</h2>

<div class="row mb-3">
  <div class="col-6 text-center">
    <button mat-button matTooltip="Banküberweisung / Vorkasse"
      (click)="payType = 1">
      <img src="../../../../assets/wire-transfer.png" width="100" />
    </button>
  </div>
  <div class="col-6 text-center">
    <button mat-button matTooltip="PayPal"
      (click)="payType = 2">
      <img src="../../../../assets/paypal.png" width="100" />
    </button>
  </div>
</div>

<div *ngIf="payType == 1" class="text-center full-width">
  <hr />
  <h2>Banküberweisung (3-4 Werktage)</h2>
  Simon Kobler<br />
  DE58 7002 0270 0010 3401 70<br />
  HYVEDEMMXXX<br />
  Verwendungszweck: RE-{{_data.id}}
</div>