import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LoginService } from 'src/app/services/login/login.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-organization-profile',
  templateUrl: './organization-profile.component.html',
  styleUrls: ['./organization-profile.component.scss']
})
export class OrganizationProfileComponent implements OnInit {

  twoFactorEnabled = false;

  constructor(private _dialog: MatDialog,
    private _login: LoginService,
    private _http: HttpClient) { }

  ngOnInit(): void {
    this._login.userSource.subscribe(user => {
      this.twoFactorEnabled = user.twoFactor;
    });
  }

  toggle2FA() {
    if (this.twoFactorEnabled) {
      this.disable2FA();
    } else {
      this.enable2FA();
    }
  }

  enable2FA() {
    const dialogRef = this._dialog.open(TwoFactorDialogComponent, {
      width: '400px'
    }).afterClosed().subscribe(status => {
      this.twoFactorEnabled = true;
    })
  }

  disable2FA() {
    const dialogRef = this._dialog.open(TwoFactorDisableDialogComponent, {
      width: '550px'
    }).afterClosed().subscribe(status => {
      if (status) {
        this.twoFactorEnabled = false;
      }
    });
  }
}

@Component({
  selector: 'app-two-factor-dialog',
  templateUrl: './two-factor-dialog.html'
})
export class TwoFactorDialogComponent implements OnInit {

  twoFactorImg = null;

  constructor(private _http: HttpClient,
    private _dialog: MatDialogRef<OrganizationProfileComponent>,
    private _snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this._http.post<{
      result: string
    }>(`${environment.api_url}/cloud/2fa`, {}).subscribe(result => {
      this.twoFactorImg = result.result;
      this._snackBar.open('2-Faktor wurde erfolgreich aktiviert!', 'ok', {
        duration: 3000,
        horizontalPosition: 'end'
      });
    }, error => {
      this._dialog.close({
        error: true
      });
    });
  }

}

@Component({
  selector: 'app-two-factor-disable-dialog',
  templateUrl: './two-factor-disable-dialog.html'
})
export class TwoFactorDisableDialogComponent implements OnInit {

  password = '';

  constructor(private _http: HttpClient,
    private _dialogRef: MatDialogRef<TwoFactorDisableDialogComponent>,
    private _snackBar: MatSnackBar) { }

  ngOnInit(): void {}

  confirm() {
    this._http.post(`${environment.api_url}/cloud/2fa/disable`, {
      password: this.password
    }).subscribe(result => {
      this._dialogRef.close(true);
      this._snackBar.open('2-Faktor wurde erfolgreich deaktiviert!', 'ok', {
        duration: 3000,
        horizontalPosition: 'end'
      });
    }, error => {
      this._snackBar.open('Fehler beim Deaktivieren der 2-Faktor Authentifizierung, eventuell falsches Passwort eingegeben?', 'ok', {
        duration: 3000,
        horizontalPosition: 'end'
      });
    });
  }
}
