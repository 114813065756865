import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Title } from '@angular/platform-browser';
import { LoginService } from 'src/app/services/login/login.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-endpoints-modules',
  templateUrl: './endpoints-modules.component.html',
  styleUrls: ['./endpoints-modules.component.scss']
})
export class EndpointsModulesComponent implements OnInit {

  accountType = 0;
  showLoader = false;
  modules = [];

  moduleModel = [];
  moduleForm: FormGroup[] = [];
  moduleFields = [];

  constructor(public _login: LoginService,
    private _title: Title,
    private _http: HttpClient,
    private _snackBar: MatSnackBar,
    private _dialog: MatDialog) { }

  ngOnInit(): void {
    this._login.userSource.subscribe(user => {
      if(user) {
        this.accountType = user.type;
        if (user.type == 2) {
          this.load();
        }
      }
    });
    this._title.setTitle("Firedesk - Module");
  }

  load() {
    this._http.get<any[]>(`${environment.api_url}/cloud/gateways/modules`).subscribe(data => {
      this.moduleForm = [];
      data.forEach(elem => {
        this.moduleForm.push(new FormGroup({}));
        this.moduleFields.push(elem.configFields);
        console.log(elem);
        this.moduleModel.push(elem.config);
        console.log(this.moduleModel);
      });
      this.modules = data;
    }, error => {
      this._snackBar.open('Fehler beim Abrufen der Module!', 'ok', {
        duration: 3000
      });
    });
  }

  toggleModule(index, name, status) {
    if (status == false) {
      this.activateModule(index, name);
    } else {
      this.disableModule(name);
    }
  }

  activateModule(index, name) {
    console.log(name);
    console.log(this.moduleModel[index]);
    this._http.post(`${environment.api_url}/cloud/gateways/modules`, {
      moduleName: name,
      config: JSON.stringify(this.moduleModel[index])
    }).subscribe(data => {
      this._snackBar.open(`Modul: ${name} wurde erfolgreich aktiviert.`, 'ok', {
        duration: 3000
      });
      this.load();
    }, error => {
      this._snackBar.open('Ein Fehler ist aufgetreten!', 'ok', {
        duration: 3000
      });
    });
  }

  disableModule(name) {
    this._http.delete(`${environment.api_url}/cloud/gateways/modules?moduleName=${name}`).subscribe(data => {
      this._snackBar.open(`Modul: ${name} wurde erfolgreich deaktiviert.`, 'ok', {
        duration: 3000
      });
      this.load();
    }, error => {
      this._snackBar.open('Ein Fehler ist aufgetreten!', 'ok', {
        duration: 3000
      });
    });
  }

}
