import { Component, Host, Inject, OnInit } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { InvoicesComponent } from '../invoices.component';

@Component({
  selector: 'app-invoices-detail',
  templateUrl: './invoices-detail.component.html',
  styleUrls: ['./invoices-detail.component.scss']
})
export class InvoicesDetailComponent implements OnInit {

  constructor(@Inject(MAT_BOTTOM_SHEET_DATA) public _data: any) { }

  ngOnInit(): void {
    
  }

  batchColor(status) {
    switch(status) {
      case 'open': return 'badge-danger';
      case 'paid': return 'badge-success';
      case 'cancelled': return 'badge-secondary';
      case 'reminder': return 'badge-warning';
    }
  }
}
