<h2 mat-dialog-title>Zweifaktor Authentifizierung</h2>

<mat-dialog-content>
  <mat-form-field class="full-width">
    <mat-label>2FA-Factor</mat-label>
    <input 
      matInput 
      [(ngModel)]="code" 
      maxlength="6" 
      inputmode="numeric"
      autocomplete="one-time-code"
      pattern="[0-9]*" />
    <mat-hint style="color: red" *ngIf="!code">Bitte geben Sie Ihren 2-Faktor code ein!</mat-hint>
  </mat-form-field>

  <button
    mat-raised-button
    class="mt-2 float-end"
    color="primary"
    (click)="confirm()">Einloggen</button>
</mat-dialog-content>