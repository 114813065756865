<h1 mat-dialog-title>Gruppen zuordnen</h1>

<div mat-dialog-content>
  <div class="m-1">
    <table mat-table [dataSource]="dataSource" matSort class="pt-2 full-width">
      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
        <td mat-cell *matCellDef="let element"> {{ element.name }} </td>
      </ng-container>

      <!-- A ction Column -->
      <ng-container matColumnDef="action" justify="end">
        <th mat-header-cell *matHeaderCellDef>
          <button mat-icon-button (click)="load()">
            <mat-icon>refresh</mat-icon>
          </button>
        </th>
        <td mat-cell *matCellDef="let element">
          <mat-slide-toggle
            [checked]="element.assigned == 1"
            (change)="toggle(element.id, $event)"></mat-slide-toggle>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
  </div>
</div>

<div mat-dialog-actions>
  <span class="big-spacer"></span>
  <button
    mat-raised-button
    mat-dialog-close="true"
    color="primary">
    schließen
  </button>
</div>
