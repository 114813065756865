import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-confirm-alert',
  templateUrl: './confirm-alert.component.html',
  styleUrls: ['./confirm-alert.component.scss']
})
export class ConfirmAlertComponent {

  title: any;
  message: any;

  constructor(public _dialogRef: MatDialogRef<ConfirmAlertComponent>,
    @Inject(MAT_DIALOG_DATA) public _data,
    private _sanitizer: DomSanitizer) {
    this.title = _sanitizer.bypassSecurityTrustHtml(_data.title);
    this.message = _sanitizer.bypassSecurityTrustHtml(_data.message);
  }

  onConfirm(): void {
    this._dialogRef.close(true);
  }

  onDismiss(): void {
    this._dialogRef.close(false);
  }

}
