import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { LoginService } from 'src/app/services/login/login.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-endpoints-actions-detail',
  templateUrl: './endpoints-actions-detail.component.html',
  styleUrls: ['./endpoints-actions-detail.component.scss']
})
export class EndpointsActionsDetailComponent implements OnInit {

  accountType = -1;
  title =  "Aktion erstellen";
  form = new FormGroup({});
  model = { name: '', trigger: '' };
  fieldsConfig = [];
  fields: FormlyFieldConfig[] = [];
  showLoader = false;
  triggerList = [];

  constructor(private _title: Title,
    private _http: HttpClient,
    private _snackBar: MatSnackBar,
    private _login: LoginService,
    private _route: ActivatedRoute) { }

  ngOnInit(): void {
    this._login.userSource.subscribe(user => {
      if(user) {
        this.accountType = user.type;
        if (user.type == 2) {
          this.loadTriggerList();

          this._route.params.subscribe(paramList => {
            if (paramList.id) {
              this.title = "Aktion bearbeiten";
              this.loadConfiguration(paramList.id);
            } 
            this._title.setTitle(`Firedesk - ${this.title}`);
          });
        }
      }
    });
  }

  loadConfigurationValues(type) {
    return new Promise((resolve, reject) => {
      this._http.get<any>(`${environment.api_url}/cloud/gateways/types/configuration/${type}`).subscribe(res => {
        console.log(res);
        this.fieldsConfig = res;
        this.buildFormly();
      }, error => {
        this.fieldsConfig = [];
        this.buildFormly();
        this._snackBar.open('Konnte Konfiguration nicht laden!', 'ok', {
          duration: 3000
        });
      });
    });
  }

  loadConfiguration(id) {
    return new Promise((resolve, reject) => {
      this._http.get<any>(`${environment.api_url}/cloud/gateways/actions/${id}`).subscribe(res => {
        console.log(res);
        this.model = res;
        this.loadConfigurationValues(res.trigger);
      }, error => {
        this._snackBar.open('Konnte Konfiguration nicht laden!', 'ok', {
          duration: 3000
        });
      });
    });
  }

  loadTriggerList() {
    this._http.get<any[]>(`${environment.api_url}/cloud/gateways/modules`).subscribe(data => {
      data.filter(s => s.active == 1).forEach(elem => {
        this.triggerList.push({
          label: elem.name,
          value: elem.name
        });
      });
      this.buildFormly();
    });
  }

  buildFormly() {
    this.fields = [
    {
      type: 'stepper',
      fieldGroup: [
        {
          templateOptions: { label: 'Wenn' },
          fieldGroup: [
            {
              key: 'name',
              type: 'input',
              templateOptions: {
                label: 'Name',
                required: true,
              }
            },
            {
              key: 'trigger',
              type: 'select',
              templateOptions: {
                label: 'Trigger Signal',
                required: true,
                options: this.triggerList,
                change: (field, event) => {
                  this.loadConfigurationValues(event.value);
                }
              }
            }
          ]
        },
        {
          templateOptions: { label: 'Und' },
          fieldGroup: this.fieldsConfig
        },
        {
          templateOptions: { label: 'Dann' },
          fieldGroup: []
        } 
      ] 
    }
  ];
  }

}
