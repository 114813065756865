<div class="clearfix">
  <button class="close" mat-icon-button [mat-dialog-close]="true" class="float-end" style="margin-top: -10px">
    <mat-icon>clear</mat-icon>
  </button>
  <h1 mat-dialog-title class="float-start">Passwort zurücksetzen</h1>
</div>
<div mat-dialog-content>
  <mat-form-field class="full-width">
    <mat-label *ngIf="_data.type == 0">Organisations-ID:</mat-label>
    <mat-label *ngIf="_data.type == 1">E-Mail:</mat-label>
    <input matInput [(ngModel)]="textInput" />
  </mat-form-field>
</div>
<div mat-dialog-actions class="clearfix">
  <button class="float-end" mat-raised-button color="primary" [disabled]="!textInput || showLoader" (click)="submit()">
    <mat-icon>vpn_key</mat-icon> Passwort zurücksetzen
  </button>
</div>
<ngx-spinner [fullScreen]="false" type="ball-scale-multiple" size="medium"></ngx-spinner>