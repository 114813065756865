<div class="row">
  <div class="col-12">
    <mat-card class="m-3">
      <mat-card-title>
        <div class="clearfix">
          <span class="float-start">
            <mat-icon>dns</mat-icon>
            <span class="aligned-with-icon">Alarmeingang Module</span>
          </span>
        </div>
      </mat-card-title>

      <mat-card-content>
        <div *ngIf="accountType == 1; else elseBlock;" class="alert alert-danger pt-2">
          <b>Fehler!</b> Für diesen Bereich haben Sie keinen Zugang!
        </div>

        <div class="mt-3" #elseBlock>
          <mat-accordion>
            <mat-expansion-panel *ngFor="let module of modules; let i = index">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <span class="online"></span> {{ module.name }}
                </mat-panel-title>
              </mat-expansion-panel-header>
              <i>{{ module.description }}</i>

              <hr />

              <form [formGroup]="moduleForm[i]">
                <formly-form [form]="moduleForm[i]" [fields]="moduleFields[i]" [model]="moduleModel[i]"></formly-form>
              </form> 

              <div class="clearfix">
                <button 
                  mat-raised-button 
                  [matTooltip]="module.active == 1 ? 'Deaktivieren' : 'Aktivieren'" 
                  class="float-end" [color]="module.active == 1 ? 'warn' : 'success'" 
                  [disabled]="module.active == 0 && !moduleForm[i].valid"
                  (click)="toggleModule(i, module.name, module.active == 1)">
                  <mat-icon>power_settings_new</mat-icon>
                </button>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>