<mat-card-title>
  <div class="clearfix">
    <mat-icon class="float-start">group</mat-icon> Alarmierungsgruppen

    <div class="float-end">
      <button
        color="primary"
        (click)="createGroup()"
        mat-raised-button>
          Neue Gruppe
          <mat-icon>add_to_photos</mat-icon>
        </button>
    </div>
  </div>
</mat-card-title>

<mat-card-subtitle>
  <i>In dieser Ansicht können Sie Ihre Gruppen verwalten und jeweilige Benutzer zuweisen.</i>
</mat-card-subtitle>

<mat-card-content>
  <div class="alert alert-info" *ngIf="dataSource.data.length == 0">
    <mat-icon>info</mat-icon> <span class="aligned-with-icon">Aktuell sind keine keine Gruppen vorhanden.</span>
  </div>

  <div class="table-responsive">
    <table mat-table [dataSource]="dataSource" matSort class="pt-2 full-width">
      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
        <td mat-cell *matCellDef="let element"> {{element.name}} </td>
      </ng-container>
  
      <!-- Organization Column -->
      <ng-container matColumnDef="count">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Zugewiesene Benutzer </th>
        <td mat-cell *matCellDef="let element"> {{element.count}} </td>
      </ng-container>
  
      <!-- A ction Column -->
      <ng-container matColumnDef="actions" justify="end">
        <th mat-header-cell *matHeaderCellDef>
          <button mat-icon-button (click)="load()">
            <mat-icon>refresh</mat-icon>
          </button>
        </th>
        <td mat-cell *matCellDef="let element">
          <button mat-icon-button matTooltip="Benutzer zuweisen" (click)="editUser(element)"><mat-icon>supervisor_account</mat-icon></button>
          <button mat-icon-button matTooltip="Gruppe bearbeiten" (click)="editGroup(element)"><mat-icon>edit</mat-icon></button>
          <button mat-icon-button matTooltip="Gruppe löschen" (click)="deleteGroup(element)"><mat-icon>delete</mat-icon></button>
        </td>
      </ng-container>
  
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
  <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
</mat-card-content>

<mat-progress-bar
    *ngIf="showLoader"
    mode="query"
    class="login-spinner"></mat-progress-bar>
