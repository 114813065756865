<div class="row">
  <div class="col-12">
    <mat-card class="m-3">
      <mat-card-title>
        <mat-icon>devices</mat-icon>
        Meine Geräte
      </mat-card-title>

      <mat-card-subtitle>
        In dieser Ansicht siehst du deine aktiven Geräte und kannst diese verwalten.
      </mat-card-subtitle>

      <mat-card-content>
        <div *ngIf="accountType == 2; else elseBlock;" class="alert alert-danger pt-2">
          <b>Fehler!</b> Für diesen Bereich haben Sie keinen Zugang!
        </div>

        <ng-template #elseBlock>
          <div class="alert alert-info" *ngIf="dataSource.data.length == 0">
            <mat-icon>info</mat-icon> <span class="aligned-with-icon">Derzeit sind keine Geräte registriert.</span>
          </div>
          <div class="alert alert-warning" *ngIf="dataSource.data.length >= 2">
            <mat-icon>warning</mat-icon> <span class="aligned-with-icon">Du hast die maximale Anzahl an Geräten erreicht!</span>
          </div>
          <div class="alert alert-primary" *ngIf="dataSource.data.length > 0">
            <mat-icon>warning</mat-icon> <span class="aligned-with-icon">Du hast derzeit <b>{{dataSource.data.length}} von maximal 2 Geräten</b> registriert.</span>
          </div>

          <table mat-table [dataSource]="dataSource" matSort class="pt-2 full-width">
            <!-- Name Column -->
            <ng-container matColumnDef="lastlogin">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Zuletzt eingeloggt </th>
              <td mat-cell *matCellDef="let element"> {{dayjs(element.lastlogin).format('DD.MM.YYYY HH:mm') }} </td>
            </ng-container>

            <!-- Organization Column -->
            <ng-container matColumnDef="platform">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Platform </th>
              <td mat-cell *matCellDef="let element">
                <img
                  src="../../../assets/icons/android.png"
                  *ngIf="element.platform == 'Android'"
                  matTooltip="Android" />
                <img
                  src="../../../assets/icons/apple.png"
                  *ngIf="element.platform == 'IOS'"
                  matTooltip="Apple IOS" />
              </td>
            </ng-container>

            <!-- A ction Column -->
            <ng-container matColumnDef="actions" justify="end">
              <th mat-header-cell *matHeaderCellDef>
                <button mat-icon-button (click)="load()">
                  <mat-icon>refresh</mat-icon>
                </button>
              </th>
              <td mat-cell *matCellDef="let element">
                <button
                  mat-icon-button
                  (click)="delete(element.id)"
                  matTooltip="Gerät entfernen">
                  <mat-icon>delete</mat-icon>
                </button>
                <button
                  mat-icon-button
                  (click)="sendPush(element.id)"
                  matTooltip="Push Benachrichtigung senden">
                  <mat-icon>send</mat-icon>
                </button>
                <button
                  mat-icon-button
                  (click)="sendAlarm(element.id)"
                  matTooltip="Test Alarm senden">
                  <mat-icon>alarm</mat-icon>
                </button>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
          <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
        </ng-template >
      </mat-card-content>
    </mat-card>
  </div>
</div>

<mat-progress-bar
    *ngIf="showLoader"
    mode="query"
    class="login-spinner"></mat-progress-bar>
