import { Injectable } from '@angular/core';
import {
 HttpInterceptor,
 HttpRequest,
 HttpHandler,
 HttpEvent,
 HttpHeaders
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoginService } from '../services/login/login.service';


@Injectable()
export class HttpAuthInterceptor implements HttpInterceptor {
  constructor(private _login: LoginService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authReq = request.clone({
      headers: new HttpHeaders({
        'Authorization': this._login.getToken()
      })
    });
    return next.handle(authReq);
  }
}
