import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { LoginService } from 'src/app/services/login/login.service';
import { environment } from 'src/environments/environment';
import * as moment from "moment";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmAlertComponent } from 'src/app/utils/confirm-alert/confirm-alert.component';
import * as dayjs from 'dayjs';

@Component({
  selector: 'app-timeouts',
  templateUrl: './timeouts.component.html',
  styleUrls: ['./timeouts.component.scss']
})
export class TimeoutsComponent implements OnInit {

  accountType = 0;
  packageType = 1;

  dayjs = dayjs;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataSource = new MatTableDataSource<any[]>();
  displayedColumns = ['name', 'time', 'day', 'actions'];
  showLoader = false;
  data = [];

  constructor(private _login: LoginService,
    private _title: Title,
    private _http: HttpClient,
    private _snackBar: MatSnackBar,
    private _dialog: MatDialog) { }

  ngOnInit(): void {
    this._login.userSource.subscribe(user => {
      if(user) {
        this.accountType = user.type;
        if (user.type == 2) {
          this._title.setTitle('Firedesk - Zeitschaltung/Probealarm');
          this.load();
        } else {
          this._title.setTitle('Keine Berechtigung!');
        }
      }
    });
  }

  load() {
    this.showLoader = true;
    this._http.get<any>(`${environment.api_url}/cloud/timeouts`).subscribe(data => {
      this.data = data;
      this.dataSource.data = data;
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.showLoader = false;
    }, error => {
      this._snackBar.open('Fehler beim Abrufen der Probealarm Konfiguration!', 'ok', {
        duration: 3000
      });
    });
  }

  toggleState(elem, $event) {
    let state = 0;
    if ($event.checked) {
      state = 1;
    }

    this._http.put(`${environment.api_url}/cloud/timeouts/${elem.id}/${state}`, {}).subscribe(() => {}, error => {
      this._snackBar.open('Fehler beim Ändern des Status der Zeitschaltung!', 'ok', {
        duration: 3000
      });
    });
  }

  getDayDescription(day) {
    switch(day) {
      case 1: return "Montag";
      case 2: return "Dienstag";
      case 3: return "Mittwoch";
      case 4: return "Donnerstag";
      case 5: return "Freitag";
      case 6: return "Samstag";
      case 7: return "Sonntag";
    }
  }

  editTimeout(elem) {
    const dialogRef = this._dialog.open(TimeoutsEditComponent, {
      data: JSON.parse(JSON.stringify(elem))
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.load();
      }
    });
  }

  deleteTimeout(elem) {
    const dialogRef = this._dialog.open(ConfirmAlertComponent, {
      data: {
        message: `Soll diese Zeitschaltung <b>${elem.name}</b> wirklich gelöscht werden?`,
        title: 'Wirklich löschen?'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this._http.delete(`${environment.api_url}/cloud/timeouts/${elem.id}`).subscribe(data => {
          this.load();
          this._snackBar.open('Zeitschaltung wurde erfolgreich gelöscht!', 'ok', {
            duration: 3000
          });
        }, error => {
          this._snackBar.open('Ein Fehler ist beim Löschen der Zeitschaltung aufgetreten!', 'ok', {
            duration: 3000
          });
        });
      }
    })
  }

  editGroups(elem) {
    this._dialog.open(TimeoutsGroupsEditComponent, {
      data: JSON.parse(JSON.stringify(elem)),
      width: '800px'
    });
  }

  createTimeout() {
    const dialogRef = this._dialog.open(TimeoutsEditComponent, {
      data: {
        name: '',
        from: '',
        to: '',
        dayOfWeek: 1,
        weekOfMonth: -1
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.load();
      }
    });
  }
}

@Component({
  selector: 'app-timeouts-edit',
  templateUrl: './popup.html'
})
export class TimeoutsEditComponent implements OnInit {

  weekDayList = [
    { name: 'Montag', value: 1 },
    { name: 'Dienstag', value: 2 },
    { name: 'Mittwoch', value: 3 },
    { name: 'Donnerstag', value: 4 },
    { name: 'Freitag', value: 5 },
    { name: 'Samstag', value: 6 },
    { name: 'Sonntag', value: 7 }
  ]

  weekOfMonthList = [
    { name: 'Jede Woche', value: -1 },
    { name: '1. Woche', value: 0 },
    { name: '2. Woche', value: 1 },
    { name: '3. Woche', value: 2 },
    { name: '4. Woche', value: 3 },
  ]

  constructor(public _dialogRef: MatDialogRef<ConfirmAlertComponent>,
    @Inject(MAT_DIALOG_DATA) public _data,
    private _http: HttpClient,
    private _snackBar: MatSnackBar) {}

  ngOnInit(): void {}

  save() {
    if (this._data.id) {
      this._http.post(`${environment.api_url}/cloud/timeouts/${this._data.id}`, this._data).subscribe(data => {
        this._snackBar.open('Einstellungen gespeichert!', 'ok', {
          duration: 3000
        });
        this._dialogRef.close(true);
      }, error => {
        this._snackBar.open('Fehler beim Speichern der Konfiguration!', 'ok', {
          duration: 3000
        });
      });
    } else {
      this._http.put(`${environment.api_url}/cloud/timeouts`, this._data).subscribe(data => {
        this._snackBar.open('Zeitschaltung wurde erstellt!', 'ok', {
          duration: 3000
        });
        this._dialogRef.close(true);
      }, error => {
        this._snackBar.open('Fehler beim Speichern der Zeitschaltung!', 'ok', {
          duration: 3000
        });
      });
    }
  }
} 

@Component({
  selector: 'app-timeouts-groups-edit',
  templateUrl: './popup-groups.html'
})
export class TimeoutsGroupsEditComponent implements OnInit {
  
  displayedColumns = ['name', 'action'];
  dataSource = new MatTableDataSource<any[]>();
  showLoader = false;
  data = [];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  
  constructor(public _dialogRef: MatDialogRef<ConfirmAlertComponent>,
    @Inject(MAT_DIALOG_DATA) public _data,
    private _http: HttpClient,
    private _snackBar: MatSnackBar) {}

  ngOnInit(): void {
    this.load();
  }

  toggle(id, event) {
    let added = 0;
    if(event.checked) added = 1;
    this._http.post(`${environment.api_url}/cloud/timeouts/${this._data.id}/groups/${id}`, {
      added: added
    }).subscribe(data => {}, error => {
      this._snackBar.open('Fehler beim Speichern der Gruppenzuweisung!', 'ok', {
        duration: 3000
      });
    });
  }

  load() {
    this._http.get<any>(`${environment.api_url}/cloud/timeouts/${this._data.id}/groups`).subscribe(data => {
      this.data = data;
      this.dataSource.data = data;
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    }, error => {
      this._snackBar.open('Fehler beim Laden der Gruppenzuweisungen!', 'ok', {
        duration: 3000
      });
    });
  }
} 
