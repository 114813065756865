<div *ngIf="this._login.isValidating()" class="loadingLogin">
  <div class="spinner text-center">
    <div class="clearfix mt-3" style="margin-left: -50px">
      <h2 class="float-start" style="letter-spacing: .1em">WIRD GELADEN</h2>
      <div class="dot-falling float-end" style="margin-left: 30px; margin-top: 9px"></div>
    </div>
  </div>
</div>
<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #drawer class="sidenav">
    <mat-nav-list>
      <div class="clearfix">
        <button mat-icon-button (click)="closeDrawer()" class="float-end me-2">
          <mat-icon>close</mat-icon>
        </button>
      </div>
      <div class="text-center">
        <div mat-list-item class="mt-3">
          <app-organization-navbar *ngIf="accountType == 2"></app-organization-navbar>
          <app-standard-navbar *ngIf="accountType == 1"></app-standard-navbar>
        </div>
      </div>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar color="red" *ngIf="this._login.isLoggedIn()">
      <button type="button" mat-icon-button
        (click)="drawer.toggle()">
        <mat-icon>menu</mat-icon>
      </button>

      <img src="../../../assets/logo.png" class="mt-1 ms-2" height="40" width="40" />
      <span class="ms-1">Firedesk</span>

      <span class="big-spacer"></span>

      <button type="button" mat-icon-button [matMenuTriggerFor]="userProfileDropdown">
        <mat-icon aria-label="Side nav toggle icon">person</mat-icon>
      </button>
      <mat-menu #userProfileDropdown="matMenu" overlapTrigger="false">
        <button mat-menu-item routerLink="profile">
          <mat-icon>supervised_user_circle</mat-icon>
          <span>Mein Profil</span>
        </button>
        <button mat-menu-item (click)="logout()">
          <mat-icon>power_settings_new</mat-icon>
          <span>Ausloggen</span>
        </button>
      </mat-menu>
    </mat-toolbar>
    <div class="loginBackground"></div>
    <router-outlet></router-outlet>
    <!-- Add Content Here -->
  </mat-sidenav-content>
</mat-sidenav-container>
