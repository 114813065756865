import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { LoginService } from 'src/app/services/login/login.service';
import { ConfirmAlertComponent } from 'src/app/utils/confirm-alert/confirm-alert.component';
import { environment } from 'src/environments/environment';
import { VehicleDialogComponent } from './vehicle-dialog/vehicle-dialog.component';

@Component({
  selector: 'app-vehicle',
  templateUrl: './vehicle.component.html',
  styleUrls: ['./vehicle.component.scss']
})
export class VehicleComponent implements OnInit {

  accountType = 0;
  displayedColumns = ['name', 'actions'];
  dataSource = new MatTableDataSource<any[]>();
  showLoader = false;
  data = [];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private _login: LoginService,
    private _http: HttpClient,
    private _dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private _title: Title) { }

  ngOnInit(): void {
    this._login.userSource.subscribe(user => {
      if(user) {
        this.accountType = user.type;

        if(this.accountType == 2) {
          this.load();
        }
      }
    });
    this._title.setTitle('Firedesk - Fahrzeuge');
  }

  search(term: string) {
    if(!term) {
      this.dataSource.data = this.data;
    } else {
      this.dataSource.data = this.data.filter(x =>
         x.name.trim().toLowerCase().includes(term.trim().toLowerCase())
      );
    }
  }

  load() {
    this.showLoader = true;
    this._http.get<any[]>(`${environment.api_url}/cloud/vehicles`).subscribe(data => {
      this.dataSource.data = data;
      this.data = data;
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.showLoader = false;
    }, error => {
      this.showLoader = false;
      this._snackBar.open('Ein Serverfehler ist aufgetreten!', 'ok', {
        duration: 3000
      });
    });
  }

  editVehicle(vehicle) {
    const dialogRef = this._dialog.open(VehicleDialogComponent, {
      width: '500px',
      data: vehicle
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.load();
      }
    });
  }

  createVehicle() {
    const dialogRef = this._dialog.open(VehicleDialogComponent, {
      width: '500px'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.load();
      }
    });
  }

  delete(vehicle) {
    const dialogRef = this._dialog.open(ConfirmAlertComponent, {
      data: {
        title: 'Löschen?',
        message: `Möchten Sie das Fahrzeug: <b>${vehicle.name}</b> löschen?`
      }
    });

    dialogRef.afterClosed().subscribe(status => {
      if(status) {
        this.showLoader = true;
        this._http.delete(`${environment.api_url}/cloud/vehicles/${vehicle.id}`).subscribe(data => {
          this.showLoader = false;
          this.load();
          this._snackBar.open('Fahrzeug wurde erfolgreich gelöscht.', 'ok', {
            duration: 3000
          });
        }, error => {
          this.showLoader = false;
          this._snackBar.open('Ein Serverfehler ist aufgetreten!', 'ok', {
            duration: 3000
          });
        });
      }
    });
  }

}
