import { environment } from './../../../../environments/environment';
import { Component, OnInit } from '@angular/core';
import { Welcome } from '../../../utils/simple/welcome';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-standard-home',
  templateUrl: './standard-home.component.html',
  styleUrls: ['./standard-home.component.scss']
})
export class StandardHomeComponent implements OnInit {

  welcomeText = '';
  deviceCount = 0;

  constructor(private _http: HttpClient) { }

  ngOnInit(): void {
    this.welcomeText = Welcome.generateText();

    this._http.get<any[]>(`${environment.api_url}/cloud/devices`).subscribe(data => {
      this.deviceCount = data.length;
    });
  }

}
