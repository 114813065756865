<button class="close" mat-icon-button [mat-dialog-close]="true"><mat-icon>clear</mat-icon></button>
<h1 mat-dialog-title>Organisation Registrieren</h1>
<div mat-dialog-content>
  <h3>Angaben zur Organisation:</h3>
  <formly-form [form]="organizationForm" [fields]="organizationFields" [model]="organizationModel"></formly-form>
  <hr />
  <h3>Zugangsdaten:</h3>
  <formly-form [form]="contactPersonForm" [fields]="contactPersonFields" [model]="contactPersonModel"></formly-form>
  <re-captcha
    (resolved)="resolvedCaptcha($event)"
    siteKey="6Lf7Mg0aAAAAAJrNGG7qWParuzJuMkDoqn_Vp39G"></re-captcha>
  <div class="float-end mt-3">
    <button
      mat-raised-button
      color="primary"
      [disabled]="!(this.organizationForm.valid && this.contactPersonForm.valid) || !recaptcha">
      <mat-icon>verified_user</mat-icon>
      Konto erstellen
    </button>
  </div>
</div>
<mat-progress-bar
    *ngIf="showLoader"
    mode="query"
    class="login-spinner"></mat-progress-bar>
