import { Component, Inject, OnInit } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';

@Component({
  selector: 'app-invoices-pay',
  templateUrl: './invoices-pay.component.html',
  styleUrls: ['./invoices-pay.component.scss']
})
export class InvoicesPayComponent implements OnInit {

  payType = 0;

  constructor(@Inject(MAT_BOTTOM_SHEET_DATA) public _data: any) { }

  ngOnInit(): void {
  }

}
