<h1 mat-dialog-title>Fahrzeug 
  <span *ngIf="!this._data">anlegen</span>
  <span *ngIf="this._data">bearbeiten</span>
</h1>

<hr />

<div mat-dialog-content>
  <div class="m-1">
    <form [formGroup]="form">
      <formly-form [form]="form" [fields]="fields" [model]="model"></formly-form>
    </form>
  </div>
</div>

<div mat-dialog-actions>
  <span class="big-spacer"></span>
  <button
    mat-raised-button
    [disabled]="!form.valid"
    (click)="save()"
    color="primary">
    Speichern
    <mat-icon>save</mat-icon>
  </button>
</div>
