<mat-card-title>
  <mat-icon>supervised_user_circle</mat-icon>
  <span class="aligned-with-icon">Ihr Profil</span>
</mat-card-title>

<mat-card-content>
  <div class="clearfix">
    <div class="float-end">
      <button 
        mat-raised-button
        (click)="toggle2FA()"
        color="primary">2-Factor Authentifizierung 
        {{ twoFactorEnabled ? 'deaktivieren' : 'aktivieren' }}
      </button>
    </div>
  </div>
</mat-card-content>